import {
  Box,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import { Edit, GroupOutlined } from "@mui/icons-material";

import { Timestamp } from "firebase/firestore";
import { DateTime } from "luxon";
import moment from "moment-timezone";
import { useDrawer } from "../../../providers/DrawerProvider";
import { formatTimeRange } from "../../../services/dateServices";
import AvatarStatusBadge from "../../AvatarStatusBadge";
import LazyEmployeeCard from "../../drawers/LazyEmployeeCard";
import EditHelpersDialog from "../dialogs/EditHelpersDialog";

const HelpersListItem = ({
  visitData = {},
  userRole = "member",
  memberId = "",
  onlyAccepted = false,
}) => {
  const {
    start = Timestamp.now(),
    end = Timestamp.now(),
    isOpenVisit = false,
    employeesNeeded = 0,
    id: visitId = "",
    timeZoneId = "America/Los_Angeles",
    employees = {},
  } = visitData || {};

  const [open, setOpen] = useState(false);
  const { openDrawer = {} } = useDrawer() || {};

  // Open edit dialog
  const handleOpen = () => {
    setOpen(true);
  };

  const isPastEvent =
    DateTime.now().toJSDate() > DateTime.fromJSDate(start?.toDate()).toJSDate();

  const getResponseStatusText = (status) => {
    switch (status) {
      case "accepted":
        return "confirmed";
      case "declined":
        return "declined";
      case "needsAction":
        return "assigned"; // This is the special case you've shown above
      case "refunded":
        return "refunded"; // This is the special case you've shown above
      default:
        return "unknown status";
    }
  };

  // Function to compare visit window with employee windows
  const isAnyWindowDifferent = () => {
    if (!employees || !start || !end) {
      return false; // Return false if visit or necessary properties are missing
    }

    const visitStart = DateTime.fromJSDate(start.toDate()).setZone(timeZoneId);
    const visitEnd = DateTime.fromJSDate(end.toDate()).setZone(timeZoneId);

    // Filter employees with "responseStatus" == "accepted"
    const acceptedEmployees = Object.values(employees).filter(
      (employee) => employee.responseStatus === "accepted"
    );

    // Check if any accepted employee's window is different
    return acceptedEmployees.some((employee) =>
      Object.values(employee.windows || {}).some((window) => {
        const windowStart = DateTime.fromJSDate(window.start.toDate()).setZone(
          timeZoneId
        );
        const windowEnd = DateTime.fromJSDate(window.end.toDate()).setZone(
          timeZoneId
        );

        return !windowStart.equals(visitStart) || !windowEnd.equals(visitEnd);
      })
    );
  };

  // Determining how to display time format
  const employeeCount =
    employees &&
    Object.values(employees).filter(
      (employee) => employee.responseStatus === "accepted"
    ).length;
  const windowsAreDifferent = isAnyWindowDifferent();
  const shouldShowTimes = employeeCount > 1 && windowsAreDifferent;

  const renderEmployeePositions = (employees, isOpenVisit, employeesNeeded) => {
    let positions = [];

    // If there are actual employees, we render them.
    if (employees) {
      positions = Object.entries(employees)
        .filter(
          ([, employee]) =>
            (!onlyAccepted && employee.responseStatus === "needsAction") ||
            employee.responseStatus === "accepted" ||
            (!onlyAccepted && employee.responseStatus === "refunded") ||
            (userRole === "admin" && employee.responseStatus === "declined") // Show "declined" if (userRole === "admin") is true
        )
        .sort(([, employeeA], [, employeeB]) => {
          if (
            employeeA.responseStatus === "declined" &&
            employeeB.responseStatus !== "declined"
          ) {
            return 1; // employeeA (declined) goes after employeeB
          } else if (
            employeeA.responseStatus !== "declined" &&
            employeeB.responseStatus === "declined"
          ) {
            return -1; // employeeA goes before employeeB (declined)
          }
          // If both are in the same category, sort by displayName
          return (employeeA.displayName || "").localeCompare(
            employeeB.displayName || ""
          );
        })
        .map(([key, employee], index) => {
          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 1,
              }}
            >
              <Stack
                key={key}
                direction="row"
                gap={1}
                alignItems="center"
                sx={{
                  cursor: "pointer",
                  pr: { xs: 0, sm: 2 },
                  borderRadius: "15px",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                  },
                }}
                // sx={{ backgroundColor: "#F5F5F5", borderRadius: "50px", pr: 2 }}
                onClick={() =>
                  openDrawer(LazyEmployeeCard, {
                    employeeId: key,
                    memberId,
                    userRole,
                  })
                }
              >
                <ListItemAvatar>
                  <AvatarStatusBadge
                    status={employee.responseStatus}
                    avatarUrl={employees[key]?.avatarUrl}
                    displayName={employees[key]?.displayName}
                    index={index}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={`${employees[key]?.displayName}`}
                  secondary={`${getResponseStatusText(
                    employee.responseStatus
                  )}${
                    employee.declineReason ? ` - ${employee.declineReason}` : ""
                  }`}
                />
              </Stack>
              {shouldShowTimes && employee?.responseStatus !== "declined" && (
                <Typography
                  variant="body2"
                  sx={{
                    backgroundColor: "#F5F5F5",
                    borderRadius: "15px",
                    py: 1,
                    px: 2,
                    my: 1,
                  }}
                >
                  {employee.windows
                    ? Object.values(employee.windows).map(
                        (window, windowIndex) => (
                          <React.Fragment key={windowIndex}>
                            {formatTimeRange(
                              moment(window?.start?.toDate())
                                .tz(timeZoneId)
                                .toDate(),
                              moment(window?.end?.toDate())
                                .tz(timeZoneId)
                                .toDate(),
                              timeZoneId
                            )}
                            <br />
                          </React.Fragment>
                        )
                      )
                    : formatTimeRange(
                        moment(start?.toDate()).tz(timeZoneId).toDate(),
                        moment(end?.toDate()).tz(timeZoneId).toDate(),
                        timeZoneId
                      )}
                </Typography>
              )}
            </Box>
          );
        });
    }

    // If the visit is open and it's still in the future, we add placeholders for the open positions.
    if (isOpenVisit && !isPastEvent) {
      positions = positions.concat(
        Array.from({ length: employeesNeeded }, (_, index) => (
          <Stack
            key={`open_position_${index}`}
            direction="row"
            gap={1}
            alignItems="center"
          >
            <ListItemAvatar>
              <AvatarStatusBadge
                status={"needsAction"}
                avatarUrl={""}
                displayName={""}
                index={-1}
              />
            </ListItemAvatar>
            <ListItemText primary={"Awaiting"} secondary={"open position"} />
          </Stack>
        ))
      );
    }

    return positions;
  };

  // Custom formatting function
  const formatTime = (dateTimeObj) => {
    return dateTimeObj.minute === 0
      ? dateTimeObj.toFormat("ha").toLowerCase() // Formats as '3pm' if minutes are 00
      : dateTimeObj.toFormat("h:mma").toLowerCase(); // Formats as '3:30pm' otherwise
  };

  const renderContent = () => (
    <>
      <ListItemIcon sx={{ minWidth: "48px" }}>
        <GroupOutlined color="primary" />
      </ListItemIcon>

      <Stack direction="column" spacing={1} sx={{ ml: { xs: -1, sm: 0 } }}>
        {renderEmployeePositions(employees, isOpenVisit, employeesNeeded)}
      </Stack>
    </>
  );

  return (
    <>
      {/* Employees section */}

      {userRole === "admin" ? (
        <>
          <ListItem sx={{ paddingX: "0px" }}>
            {renderContent()}
            <ListItemSecondaryAction sx={{ right: "0px" }}>
              <IconButton>
                <Edit onClick={handleOpen} />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </>
      ) : (
        <ListItem sx={{ paddingX: "0px" }}>{renderContent()}</ListItem>
      )}

      <EditHelpersDialog
        open={open}
        setOpen={setOpen}
        visitId={visitId}
        start={start}
        end={end}
        timeZoneId={timeZoneId}
        visitEmployees={employees}
      />
    </>
  );
};

export default HelpersListItem;
