import { Box, Container } from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { db } from "../../firebase";
import JobLeadForm from "../../forms/JobLeadForm";
import useLogPageVisit from "../../hooks/useLogPageVisit";
import useTrackVisibility from "../../hooks/useTrackVisibility";
import Logo from "../../svgs/Logo";
import EmployeeJobDescription from "./sections/EmployeeJobDescription";
import HiringLanding from "./sections/HiringLanding";
import WebFooter from "./sections/WebFooter";

function HiringPage() {
  const getSystemData = () => {
    return {
      device: navigator.userAgent || "unknown device",
      screenSize: `${window.screen.width || "unknown"}x${
        window.screen.height || "unknown"
      }`,
      language: navigator.language || "unknown",
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone || "unknown",
      effectiveType: navigator.connection
        ? navigator.connection.effectiveType || "unknown"
        : "unknown",
      type: navigator.connection
        ? navigator.connection.type || "unknown"
        : "unknown",
      windowSize: `${window.innerWidth || "unknown"}x${
        window.innerHeight || "unknown"
      }`,
    };
  };

  useEffect(() => {
    const initializeSession = async () => {
      const systemData = getSystemData();

      // Grab the facebook campaign ID
      const params = new URLSearchParams(window.location.search);
      const utmSource = params.get("utm_source") || "";

      if (utmSource) {
        localStorage.setItem("utm_source", utmSource);
      }

      let storedSessionId = localStorage.getItem("sessionId");
      if (!storedSessionId) {
        storedSessionId = uuidv4();
        localStorage.setItem("sessionId", storedSessionId);

        try {
          await setDoc(
            doc(db, "sessions", storedSessionId),
            {
              created: new Date(),
              utmSource,
              "~systemData": systemData, // Store system data
            },
            { merge: true }
          );
        } catch (err) {
          console.log("error setting initial document: ", err);
        }
      }
    };

    initializeSession();
  }, []);

  useLogPageVisit("/hiring");

  const ref = useTrackVisibility(() => {}, `/hiring/"application form"`); // Pass the logMessage here

  return (
    <div>
      {/* <GuestHeader /> */}
      <Box
        sx={{
          width: "100vw",
          pb: 2,
          backgroundColor: "white", // "#F5F5F5",
          boxShadow: "0 6px 12px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Box
          alignSelf="flex-start"
          sx={{ width: "250px", pt: 2, mx: "auto" }} // Explicitly giving the container a size
        >
          <Link to="/" style={{ textDecoration: "none" }}>
            <Logo style={{ width: "100%", height: "100%" }} />{" "}
          </Link>
          {/* Ensuring the logo takes up all the available space */}
        </Box>
      </Box>
      <HiringLanding />
      <EmployeeJobDescription pageName="/hiring" />
      {/* <div id="apply" />
      <Container ref={ref} maxWidth="sm" sx={{ mt: 4, mb: 15 }}>
        <JobLeadForm />
      </Container> */}
      <WebFooter pageName="/hiring" />
    </div>
  );
}

export default HiringPage;
