import {
  Call as CallIcon,
  PhoneDisabled as PhoneDisabledIcon,
  Phone as PhoneIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Drawer,
  Fab,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../providers/AuthProvider";
import { useTwilio } from "../../providers/TwilioProvider";
import DialPad from "./DialPad";
import { useDrawer } from "../../providers/DrawerProvider";
import ResponsiveDialog from "../dialogs/ResponsiveDialog";
import { formatPhoneNumber } from "../../services/stringServices";
import LiveCallAnimation from "./LiveCallAnimation";

const drawerWidth = 300;

const CallInterface = () => {
  const {
    callerInfo,
    incomingCall,
    callInProgress,
    answerCall,
    declineCall,
    makeOutgoingCall,
  } = useTwilio();

  const { openDrawer } = useDrawer();
  const [drawerOpen, setDrawerOpen] = useState(false); // Track drawer state
  const [callTimer, setCallTimer] = useState(0);
  const { customClaims } = useAuth();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Update the call duration every second when a call is in progress
  useEffect(() => {
    let timer;
    if (callInProgress) {
      timer = setInterval(() => {
        setCallTimer((prev) => prev + 1);
      }, 1000);
    } else {
      setCallTimer(0);
    }
    return () => clearInterval(timer);
  }, [callInProgress]);

  useEffect(() => {
    if (incomingCall === null) return;

    setDrawerOpen(true)
  }, [incomingCall]);

  const isAdmin = customClaims?.isAdmin || false;
  if (!isAdmin) return;

  const isCallActive = incomingCall || callInProgress;
  const minutes = Math.floor(callTimer / 60);
  const seconds = callTimer % 60;

  const toggleDrawer = () => setDrawerOpen((prev) => !prev);

  return (
    <>
      {/* Floating FAB */}
      {isCallActive && (
        <Fab
          color="primary"
          variant="extended"
          onClick={toggleDrawer}
          sx={{
            position: "fixed",
            right: isMobile ? theme.spacing(1) : theme.spacing(3),
            bottom: isMobile ? theme.spacing(10) : theme.spacing(3),
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            px: 2,
            gap: 1,
          }}
        >
          <LiveCallAnimation />
          {callInProgress && (
            <Box sx={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
              <Typography variant="body2" sx={{ fontWeight: "600" }}>
                {formatPhoneNumber(callerInfo)}
              </Typography>
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ fontSize: "0.7rem" , color: "white"}}
              >
                {minutes}:{seconds.toString().padStart(2, "0")}
              </Typography>
            </Box>
          )}
        </Fab>
      )}

      {!isCallActive && (
        <Fab
          color="primary"
          onClick={() =>
            openDrawer(DialPad, {
              title: "Dial pad",
              onCall: makeOutgoingCall,
              width: { xs: "100%", sm: "350px" },
            })
          }
          sx={{
            position: "fixed",
            right: isMobile ? theme.spacing(1) : theme.spacing(3),
            bottom: isMobile ? theme.spacing(10) : theme.spacing(3),
          }}
        >
          <CallIcon />
        </Fab>
      )}

      {/* Drawer for Call Interface */}
      <ResponsiveDialog
        desktopAnchor="right"
        anchor="bottom"
        open={drawerOpen}
        onClose={()=>setDrawerOpen(false)}
        width="300px"
      >
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="center"
          alignItems="center"
          p={2}
        >
          <Card
            variant="outlined"
            style={{ width: "100%", textAlign: "center", borderRadius: 8 }}
          >
            <CardContent>
              {/* Incoming Call Screen */}
              {incomingCall && !callInProgress && (
                <>
                  <Typography variant="h6" gutterBottom>
                    Incoming Call
                  </Typography>
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    gutterBottom
                  >
                    {callerInfo}
                  </Typography>
                  <Box
                    sx={{
                      mt: 2,
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                    }}
                  >
                    <Button
                      variant="contained"
                      color="success"
                      startIcon={<PhoneIcon />}
                      onClick={answerCall}
                    >
                      Answer
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      startIcon={<PhoneDisabledIcon />}
                      onClick={declineCall}
                    >
                      Decline
                    </Button>
                  </Box>
                </>
              )}

              {/* Call In Progress Screen */}
              {callInProgress && (
                <>
                  <Typography variant="h6" gutterBottom>
                    Call In Progress
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    {callerInfo}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" mt={1}>
                    Duration: {minutes}:{seconds.toString().padStart(2, "0")}
                  </Typography>
                  <Box mt={2}>
                    <Button
                      variant="contained"
                      color="error"
                      startIcon={<PhoneDisabledIcon />}
                      onClick={declineCall}
                    >
                      Hang Up
                    </Button>
                  </Box>
                </>
              )}
            </CardContent>
          </Card>
        </Box>
      </ResponsiveDialog>
    </>
  );
};

export default CallInterface;
