import {
  Timestamp,
  addDoc,
  collection,
  doc,
  increment,
  setDoc,
} from "firebase/firestore";
import { geohashForLocation } from "geofire-common";
import React, { createContext, useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { db } from "../firebase";
import { useAuth } from "./AuthProvider";

// Create Context
const SessionContext = createContext(null);

// Custom Hook to access context
export const useSession = () => {
  return useContext(SessionContext);
};

// Helper to fetch IP-based geolocation
const getIpLocation = async () => {
  try {
    const response = await fetch("https://ipapi.co/json/");
    const data = await response.json();
    // Generate geohash for the location
    const geohash = geohashForLocation([
      data?.latitude || 0,
      data?.longitude || 0,
    ]);

    // Include geohash in the returned data
    return { ...data, geohash };
  } catch (error) {
    console.error("Failed to fetch location:", error);
    return null;
  }
};

const getSystemData = async () => {
  const { userAgent = "unknown device", platform, connection } = navigator;

  const isTouchDevice =
    "ontouchstart" in window || navigator.maxTouchPoints > 0;

  return {
    // Device and platform details
    deviceType: getDeviceType(userAgent, isTouchDevice).type, // Detailed type (e.g., iPhone, Mac)
    deviceCategory: getDeviceType(userAgent, isTouchDevice).category, // "mobile" or "desktop"
    isTouchDevice,
    browser: getBrowserInfo(userAgent),

    // Screen and display
    screenSize: `${window.screen.width || "unknown"}x${
      window.screen.height || "unknown"
    }`,

    // Network information
    effectiveType: connection?.effectiveType || "unknown",

    // Locale and timezone
    language: navigator.language || "unknown",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone || "unknown",

    // Preferences
    // cookiesEnabled: navigator.cookieEnabled || false,
    // doNotTrack: navigator.doNotTrack || "unknown",
  };
};

// Enhanced device type detection
const getDeviceType = (userAgent, isTouchDevice) => {
  const ua = userAgent.toLowerCase();

  // Determine device type
  if (/android/.test(ua))
    return { type: "Android Phone/Tablet", category: "mobile" };
  if (/iphone/.test(ua)) return { type: "iPhone", category: "mobile" };
  if (/ipad|macintosh/.test(ua) && isTouchDevice)
    return { type: "iPad", category: "mobile" };
  if (/macintosh/.test(ua)) return { type: "Mac", category: "desktop" };
  if (/windows/.test(ua)) return { type: "Windows PC", category: "desktop" };
  if (/linux/.test(ua)) return { type: "Linux PC", category: "desktop" };

  // General browser types
  if (/mobile/.test(ua)) return { type: "Mobile Browser", category: "mobile" };
  if (/tablet/.test(ua)) return { type: "Tablet Browser", category: "mobile" };

  return { type: "Desktop Browser", category: "desktop" };
};

// Browser detection
const getBrowserInfo = (userAgent) => {
  const match = userAgent.match(
    /(firefox|msie|trident|chrome|safari|edge|opera|opr|edg)\/?\s*(\d+)/i
  );
  if (match && match[1]) {
    return `${match[1]} ${match[2] || "unknown"}`;
  }
  return "unknown";
};

// Helper to detect bots
const isBot = () => {
  const userAgent = navigator.userAgent || "";

  // Comprehensive bot keyword list (includes common bot user agents)
  const botKeywords = [
    "Googlebot",
    "Bingbot",
    "Slurp",
    "DuckDuckBot",
    "Baiduspider",
    "YandexBot",
    "Sogou",
    "Exabot",
    "facebot",
    "ia_archiver",
    "AdsBot",
    "SemrushBot",
    "AhrefsBot",
    "MJ12bot",
    "SEMrushBot",
    "DotBot",
    "Twitterbot",
    "Pingdom",
    "LinkedInBot",
    "Pinterestbot",
    "AppEngine-Google",
    "Mediapartners-Google",
    "Google-Read-Aloud",
  ];

  // Check for common bot indicators in user-agent
  const isUserAgentBot = botKeywords.some((keyword) =>
    new RegExp(keyword, "i").test(userAgent)
  );

  // Behavioral checks for headless or bot-like behavior
  const isHeadless = navigator.webdriver || false; // Headless browsers set navigator.webdriver to true
  const missingStandardFeatures =
    !window.chrome || !window.navigator || !window.navigator.plugins; // Detect lack of standard browser features
  const isScreenSizeSuspicious =
    window.screen.width === 0 || window.screen.height === 0; // Unusual screen sizes

  // Return true if any of the conditions match bot behavior
  return (
    isUserAgentBot ||
    isHeadless ||
    missingStandardFeatures ||
    isScreenSizeSuspicious
  );
};

// SessionProvider Component
export const SessionProvider = ({ children }) => {
  const { currentUser } = useAuth();

  const [sessionId, setSessionId] = useState("");
  const [locationData, setLocationData] = useState(null);
  // we care about
  //  .city = "Anacortes"
  //  .region = "Washington"
  //  .region_code = "WA"
  //  .postal = "98221"
  //  .latitude = 48.5181
  //  .longitude = -122.6385

  const [systemData, setSystemData] = useState(null);
  const [utmSource, setUtmSource] = useState(null);

  // Initialize session when the provider mounts
  useEffect(() => {
    const initializeSession = async () => {
      // Check if the user is a bot
      const isBotUser = isBot();
      if (isBotUser) return;

      // Get session data
      const systemData = await getSystemData();
      const params = new URLSearchParams(window.location.search);
      const paramsUtmSource = params.get("utm_source") || "";
      const locationData = await getIpLocation();

      // Check sessionId existance
      let storedSessionId = localStorage.getItem("sessionId");

      // Update session state variables
      setLocationData(locationData);
      setSystemData(systemData);
      setUtmSource(paramsUtmSource);

      // Save params utm_source if they exist
      if (paramsUtmSource) {
        localStorage.setItem("utm_source", paramsUtmSource);
      }

      // Create new session document. Skip if we're logged in or there's already a sessionId existing.
      if (!storedSessionId && !currentUser?.uid) {
        storedSessionId = uuidv4();
        localStorage.setItem("sessionId", storedSessionId);

        try {
          await setDoc(
            doc(db, "sessions", storedSessionId),
            {
              firstName: "",
              lastName: "",
              displayName: "",
              created: new Date(),
              utmSource: paramsUtmSource,
              location: {
                city: locationData?.city || "",
                state: locationData?.region || "",
                zipCode: locationData?.postal || "",
                geohash: locationData?.geohash.substring(0, 6),
                lat: locationData?.latitude || 0,
                lng: locationData?.longitude || 0,
              },
              systemData,
              locationData,
            },
            { merge: true }
          );
          console.log("Session initialized.");
        } catch (err) {
          console.error("Error initializing session:", err);
        }
      }

      // Set sessionId once we've loaded or created it
      setSessionId(storedSessionId);
    };

    initializeSession();
  }, []);

  // Log the session start, once all state variables are loaded
  useEffect(() => {
    if (!(currentUser?.uid || sessionId) || !systemData || !locationData)
      return;

    // Set userId - if not logged in, use sessionId as fallback.
    const userId = currentUser?.uid || sessionId;

    // Prep systemData props and fallback
    const {
      deviceCategory = "device",
      deviceType = "unknown device type",
      browser = "unknown browser",
    } = systemData || {};

    // Prep locationData props and fallback
    const {
      city = "unknown city",
      region_code = "unknown region",
      postal = "unknown postal code",
    } = locationData || {};

    // Construct log summary
    const summary = `New ${deviceCategory} session started on a ${deviceType} using ${browser}, located in ${city}, ${region_code} ${postal}.`;

    // Create final log
    createLog({
      idsArr: [userId],
      summary,
      logType: "activity",
    });
  }, [sessionId, locationData, systemData, currentUser?.uid]);

  // Helper function to generate the summary
  const generateSummary = (
    initiatorName,
    receiverName,
    interactionType,
    madeContact
  ) => {
    // Format the interaction types into natural language
    const interactionSummary = formatInteractionTypes(interactionType);

    // Use ternary operator to determine whether contact was made
    return `${initiatorName} ${interactionSummary} ${receiverName} ${
      madeContact ? "and had a conversation." : "with no response."
    }`;
  };

  // Helper function to convert interaction types into natural language
  const formatInteractionTypes = (interactionType) => {
    const formattedTypes = interactionType.map((type) => {
      switch (type) {
        case "call":
          return "called";
        case "text":
          return "texted";
        case "email":
          return "emailed";
        case "voicemail":
          return "left a voicemail";
        case "in-person":
          return "was in-person with";
        case "automated email":
          return "sent an automated email to";
        case "automated text":
          return "sent an automated text to";
        default:
          return type;
      }
    });

    // Join the interaction types into a natural language sentence
    if (formattedTypes.length === 0) {
      return ""; // Return an empty string if no types are present
    } else if (formattedTypes.length === 1) {
      return formattedTypes[0]; // Return the single item if there's only one
    } else if (formattedTypes.length === 2) {
      return formattedTypes.join(" and "); // Join with "and" for exactly two items
    } else {
      // For three or more items
      return (
        formattedTypes.slice(0, -1).join(", ") +
        ", and " +
        formattedTypes[formattedTypes.length - 1]
      );
    }
  };

  // Function to create a communication log
  const createCommunicationLog = async ({
    madeContact, // bool TRUE or FALSE
    collectionName = "", // e.g., "members" or "leads"
    idsArr, // Array of related IDs, e.g., ["memberId456", "employee123", "visit789"]
    description, // Brief summary of the communication
    interactionType, // ["Call", "Email", "Text", ...]
    initiator, // { type: "employee" || "member" || "system", id: "employee123", displayName: "Alex R.", avatarUrl: "" }
    receiver, // { type: "employee" || "member" || "system", id: "member123", displayName: "John Doe", avatarUrl: "" }
    tags = [], // Optional tags for the log (e.g., ["mailbox_full", "call_failed"])
    sentiment = 3, // Default sentiment rating is neutral
    interest = 3, // Default interest rating is neutral
    priority = 1, // Default priority level
    flags = [], // Optional flag, e.g., ["payment", "urgent"]
    communicationDate,
    followUpDate,
  }) => {
    try {
      // Generate the summary based on the initiator, receiver, and interaction
      const summary = generateSummary(
        initiator.displayName,
        receiver.displayName,
        interactionType,
        madeContact
      );

      // Core log structure
      const log = {
        // Identifiers
        collection: collectionName || "", // "members" or "leads"
        idsArr, // Array of related IDs
        accessLevel: "private", // "public", "private", or "admin"
        logType: "communication", // Type is "communication" for communication logs
        flags, // Optional flags
        systemData,
        locationData,
        // Core log fields
        summary, // Brief summary of the communication
        created: Timestamp.now(), // Created timestamp
        edited: null, // Edited timestamp (same as created for new logs)

        // Communication-specific fields (communication details)
        communication: {
          interactionType, // e.g., ["Call", "Email"]
          description, // A description of what was discussed or what occurred
          communicationDate: Timestamp.fromDate(communicationDate.toDate()), // Convert to Firestore Timestamp
          // followUpDate: Timestamp.fromDate(followUpDate.toDate()), // Convert to Firestore Timestamp
          tags, // Tags like "mailbox_full", "call_failed", etc.
          // sentiment, // Sentiment rating (1-5)
          // interest, // Interest rating (1-5)
          // priority, // Priority level
          initiator, // Details of the person/system who initiated the interaction
          receiver, // Details of the person/system who received the interaction
          madeContact,
        },
      };

      // Add the log to the Firestore logs collection
      const logRef = collection(db, "logs"); // Referencing the logs collection in Firestore
      await addDoc(logRef, log); // Adding the log document to Firestore
    } catch (error) {
      console.error("Error creating communication log:", error);
    }
  };

  // Function to create a basic log
  const createLog = async ({
    collectionName = "", // e.g., "members" or "leads"
    idsArr, // Array of related IDs, e.g., ["memberId456", "employee123", "visit789"]
    summary, // Brief summary of the log
    flags = [], // Optional flag, e.g., ["payment", "urgent"]
    accessLevel = "private",
    logType = "basic",
  }) => {
    try {
      // Core log structure
      const log = {
        // Identifiers
        collection: collectionName || "", // "members" or "leads"
        idsArr, // Array of related IDs
        accessLevel, // "public", "private", or "admin"
        logType, // Type is "communication" for communication logs
        flags, // Optional flags
        systemData,
        locationData,

        // Core log fields
        summary, // Single line log summary
        created: Timestamp.now(), // Created timestamp
        edited: null, // Edited timestamp (same as created for new logs)
      };

      // Add the log to the Firestore logs collection
      const logRef = collection(db, "logs"); // Referencing the logs collection in Firestore
      await addDoc(logRef, log); // Adding the log document to Firestore
    } catch (error) {
      console.error("Error creating communication log:", error);
    }
  };

  // Function to create a communication log
  const createVisitLog = async ({
    collectionName = "", // e.g., "members" or "leads"
    idsArr, // Array of related IDs, e.g., ["memberId456", "employee123", "visit789"]
    summary, // Brief summary of the log
    flags = [], // Optional flag, e.g., ["payment", "urgent"]
    accessLevel = "private",
    logType = "visit",
    visitId,
  }) => {
    try {
      // Core log structure
      const log = {
        // Identifiers
        collection: collectionName || "", // "members" or "leads"
        idsArr, // Array of related IDs
        accessLevel, // "public", "private", or "admin"
        logType, // Type is "communication" for communication logs
        flags, // Optional flags

        // Core log fields
        summary, // Single line log summary
        created: Timestamp.now(), // Created timestamp
        edited: null, // Edited timestamp (same as created for new logs)
        systemData,
        locationData,

        visit: {
          visitId,
        },
      };

      // Add the log to the Firestore logs collection
      const logRef = collection(db, "logs"); // Referencing the logs collection in Firestore
      await addDoc(logRef, log); // Adding the log document to Firestore
    } catch (error) {
      console.error("Error creating communication log:", error);
    }
  };

  // Helper functions now inside the provider with early returns
  const logPageVisit = async (path) => {
    if (!currentUser?.uid && !sessionId) return;
    const userId = currentUser?.uid || sessionId; // if not logged in, use sessionId as fallback.

    createLog({
      idsArr: [userId],
      summary: `Viewed the page ${path}`,
      logType: "activity",
    });

    // Skip session logging if the user is already logged in.
    if (currentUser?.uid || !sessionId) return;

    try {
      await setDoc(
        doc(db, "sessions", sessionId),
        {
          lastVisited: new Date(),
          totalPageViews: increment(1),
        },
        { merge: true }
      );
    } catch (err) {
      console.log("Error logging page visit: ", err);
    }
  };

  const logSessionActivity = async (value) => {
    if (!currentUser?.uid && !sessionId) return;
    const userId = currentUser?.uid || sessionId; // if not logged in, use sessionId as fallback.

    createLog({
      idsArr: [userId],
      summary: value,
      logType: "activity",
    });
  };

  return (
    <SessionContext.Provider
      value={{
        sessionId,
        systemData,
        locationData,
        utmSource,
        logPageVisit,
        logSessionActivity,
        createCommunicationLog,
        createLog,
        createVisitLog,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
