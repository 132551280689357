import React, { createContext, useCallback, useContext, useState } from "react";
import ResponsiveDialog from "../components/dialogs/ResponsiveDialog";
import ScheduleVisitAdmin from "../components/ScheduleVisitAdmin";
import { useAuth } from "./AuthProvider";
import { useMember } from "./MemberProvider";

const ScheduleVisitContext = createContext();

export const ScheduleVisitProvider = ({ children }) => {
  const { currentUser } = useAuth();
  const { memberData, memberPrivateData, memberAdminData, memberAccountData } =
    useMember();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedVisit, setSelectedVisit] = useState({});
  const [filters, setFilters] = useState({
    duration: 2,
    frequency: 0,
    employeeIds: [],
    numEmployees: "1",
  });

  const openScheduleVisit = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeScheduleVisit = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <ScheduleVisitContext.Provider
      value={{
        openScheduleVisit,
        setSelectedVisit,
        selectedVisit,
        filters,
        setFilters,
      }}
    >
      {children}
      <ResponsiveDialog
        title={"Book a visit"}
        open={isOpen}
        onClose={closeScheduleVisit}
        width="800px"
        desktopAnchor={"right"}
        fullHeight={true}
        backgroundCloses={false}
      >
        <ScheduleVisitAdmin
          selectedVisit={selectedVisit}
          filters={filters}
          handleClose={closeScheduleVisit}
          isOpen={isOpen}
          memberId={currentUser?.uid}
          memberData={memberData}
          memberPrivateData={memberPrivateData}
          memberAdminData={memberAdminData}
          memberAccountData={memberAccountData}
        />
      </ResponsiveDialog>
    </ScheduleVisitContext.Provider>
  );
};

export const useScheduleVisit = () => useContext(ScheduleVisitContext);
