import { Call, Check } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Checkbox,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import bbbBadge from "../../../assets/AB-seal-horz.svg";
import PhoneField from "../../../components/fields/PhoneField";
import VisitReviewCarousel from "../../../components/reviews/VisitReviewCarousel";
import { db, functions } from "../../../firebase";
import { extractPhoneNumber } from "../../../services/inputServices";

function IntakeForm({ setLeadCaptured }) {
  const theme = useTheme();
  const [values, setValues] = useState({
    name: "",
    phone: "",
    zipCode: "",
    message: "",
    tasks: [], // Add tasks array
  });
  const [errors, setErrors] = useState({});
  const [showRates, setShowRates] = useState([]);
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // const [mode] = useState(Math.random() < 0.5 ? "benefits" : "reviews");
  const [mode] = useState("reviews");

  const tasks = [
    "Gardening",
    "Yard Work",
    "Cleaning",
    "Garage Help",
    "Organization",
    "Heavy Lifting",
    "Tech Help",
    "Decoration",
    "Errands",
    "Furniture",
    "Companion",
    "Odd Jobs",
  ];

  const TaskCheckboxes = ({ tasks, values, handleChange }) => (
    <Box sx={{ mb: 2 }}>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          columnGap: 0,
          rowGap: 0,
        }}
      >
        {tasks.map((task, index) => (
          <ListItemButton
            key={index}
            onClick={() => {
              const newTasks = values.tasks.includes(task)
                ? values.tasks.filter((t) => t !== task)
                : [...values.tasks, task];
              handleChange({ target: { name: "tasks", value: newTasks } });
            }}
            sx={{
              flex: "1 0 45%", // each item takes up roughly half of the container's width with some margin
              display: "flex",
              alignItems: "center",
              padding: "0px 0px",
            }}
          >
            <Checkbox
              checked={values.tasks.includes(task)}
              tabIndex={-1}
              disableRipple
              inputProps={{ "aria-labelledby": `task-checkbox-${index}` }}
            />
            <ListItemText id={`task-checkbox-${index}`} primary={task} />
          </ListItemButton>
        ))}
      </Box>
    </Box>
  );

  const handleSave = async (event, name) => {
    event.preventDefault();
    if (!validateStep(name)) return;
    setLoading(true);

    let utmSource = localStorage.getItem("utm_source");
    let storedSessionId = localStorage.getItem("sessionId");

    const leadData = {
      displayName: values?.name || "",
      phone: extractPhoneNumber(values?.phone || ""),
      crm: {
        leadCreated: new Date(),
        lastContactedDate: false,
        followUpDate: new Date(),
        contactedCount: 0,
        source: "pricing_page",
        timeline: {
          pricing_page: new Date(),
        },
        utmSource: utmSource || "",
        message: values?.message || "",
      },
    };

    console.log("leadData: ", leadData);

    const cleanData = (data) => {
      return Object.fromEntries(
        Object.entries(data)
          .filter(([_, v]) => v !== undefined && v !== null && v !== "")
          .map(([k, v]) =>
            v && typeof v === "object" && !(v instanceof Date)
              ? [k, cleanData(v)]
              : [k, v]
          )
      );
    };

    // Clean leadData to avoid sending undefined, null, or empty values
    const filteredLeadData = cleanData(leadData);

    console.log("filteredLeadData: ", filteredLeadData);

    // Save the lead to Firebase
    // await setDoc(doc(db, "leads", storedSessionId), filteredLeadData, {
    //   merge: true,
    // });

    const createLead = httpsCallable(functions, "createLead");
    createLead({ ...filteredLeadData, sessionId: storedSessionId });

    // await addDoc(collection(db, "leads"), leadData);
    console.log("Lead saved successfully");

    if (storedSessionId) {
      try {
        await setDoc(
          doc(db, "sessions", storedSessionId),
          {
            _pricingSubmitted: new Date(),
            _source: "skip_pricing",
            _name: values?.name || "",
            _phone: values?.phone || "",
          },
          { merge: true }
        );
      } catch (err) {
        console.log("error setting initial document: ", err);
      }
    }

    // Set a flag in localStorage indicating the form has been leadCaptured
    localStorage.setItem("leadCaptured", "true");

    setLoading(false);
    setLeadCaptured(true);
  };

  const handleSkip = async (event) => {
    event.preventDefault();
    if (!validateZip()) return;
    setLoading(true);

    let utmSource = localStorage.getItem("utm_source");
    let storedSessionId = localStorage.getItem("sessionId");

    const leadData = {
      name: values?.name || "",
      phone: extractPhoneNumber(values?.phone || ""),
      location: {
        zipCode: values?.zipCode | "",
      },
      crm: {
        leadCreated: new Date(),
        lastContactedDate: false,
        followUpDate: new Date(),
        contactedCount: 0,
        source: "skip_pricing",
        timeline: {
          skip_pricing: new Date(),
        },
        utmSource: utmSource || "",
        message: values?.message || "",
        meta: {
          pricingPageVariant: mode,
        },
      },
    };

    const cleanData = (data) => {
      return Object.fromEntries(
        Object.entries(data)
          .filter(([_, v]) => v !== undefined && v !== null && v !== "")
          .map(([k, v]) =>
            v && typeof v === "object" && !(v instanceof Date)
              ? [k, cleanData(v)]
              : [k, v]
          )
      );
    };

    // Clean leadData to avoid sending undefined, null, or empty values
    const filteredLeadData = cleanData(leadData);

    // Save the lead to Firebase
    // await setDoc(doc(db, "leads", storedSessionId), filteredLeadData, {
    //   merge: true,
    // });
    // console.log("Lead saved successfully");

    const createLead = httpsCallable(functions, "createLead");
    createLead({ ...filteredLeadData, sessionId: storedSessionId });

    if (storedSessionId) {
      try {
        await setDoc(
          doc(db, "sessions", storedSessionId),
          {
            _skippedForm: new Date(),
            _zipCode: values?.zipCode || "",
            _source: "skip_pricing",
            _name: values?.name || "",
            _phone: values?.phone || "",
          },
          { merge: true }
        );
      } catch (err) {
        console.log("error setting initial document: ", err);
      }
    }

    // Set a flag in localStorage indicating the form has been leadCaptured
    // localStorage.setItem("leadCaptured", "true");
    localStorage.setItem("zipCode", values?.zipCode || "");

    setLoading(false);
    setLeadCaptured(true);
  };

  const benefits = [
    "Licensed and insured",
    // "BBB accredited",
    "Choose your helper",
    "Flat-rate regardless of tasks",
    "Reliable help on-demand",
    // "Help with 100's of to-do's",
    "1-hour visit minimum",
    // "Schedule 3-4 days in advance",
    // "Availability within the week",
    // "Hard working and efficient",
    // "End anytime with no fees",
    "Hundreds of 5-star reviews",
    // "Earn a free hour per referral",
    // "Mix and match any tasks to fill the visit",
    // "Senior-friendly online scheduling",
    // "Money back guarantee",
    // "Schedule recurring visits",
    // "Same day visits",
    // ... more benefits
  ];

  const validateStep = (name) => {
    let tempErrors = {};
    if (name) {
      if (!values.name) tempErrors.name = "Please enter your name";
    }
    if (!values.phone || values.phone.replace(/\D/g, "").length !== 10)
      tempErrors.phone = "Please enter your phone.";

    // Add validation for zipCode not to exceed 5 digits
    if (!values.zipCode || values.zipCode.length < 5)
      tempErrors.zipCode = "Please enter your zip code.";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const validateZip = () => {
    let tempErrors = {};
    // Add validation for zipCode not to exceed 5 digits
    if (!values.zipCode || values.zipCode.length < 5)
      tempErrors.zipCode = "Please enter your zip code.";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;
    const actualValue = type === "checkbox" ? checked : value;
    setValues((prevValues) => ({ ...prevValues, [name]: actualValue }));
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[name];
      return newErrors;
    });
  };

  const handleZipChange = (e) => {
    // Only keep digits and limit to the first 5
    let value = e.target.value.replace(/\D/g, "").slice(0, 5);
    // Update the event's value before passing it to handleChange
    e.target.value = value;
    handleChange(e);
  };

  const renderBenefits = () => {
    return (
      <List dense={true}>
        {benefits.map((benefit, idx) => (
          <ListItem key={idx} sx={{ px: 0 }}>
            <Check color="primary" sx={{ mr: 1 }} />
            {benefit === "Hundreds of 5-star reviews" ? (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="body1">
                  Hundreds of{" "}
                  <Link
                    component="span"
                    to="/reviews"
                    style={{
                      fontWeight: "600",
                      color: theme.palette.primary.main,
                    }}
                  >
                    member reviews
                  </Link>
                </Typography>
              </Box>
            ) : (
              <Typography variant="body1">{benefit}</Typography>
            )}
          </ListItem>
        ))}
      </List>
    );
  };

  const renderOwner = () => (
    <ButtonBase
      fullWidth
      component="a"
      href="tel:+13603366344"
      sx={{
        textTransform: "none",
        display: "flex",
        alignItems: "center",
        backgroundColor: "#F5F5F5",
        p: 1,
        my: 1,
        height: "100%",
        borderRadius: "50px",
      }}
    >
      <Avatar
        src={
          "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2F9nRhRtIUV1fdKXANdzbI4wGli4E2?alt=media&token=d1e3dfd6-5fe0-4bca-b762-2eb77b5792a6"
        }
        alt="Alex Rodriguez"
        sx={{ width: 64, height: 64 }}
      />
      <Box sx={{ ml: 2 }}>
        <Typography variant="body1">Alex Rodriguez</Typography>
        <Typography variant="body2" sx={{ fontStyle: "italic" }}>
          Owner, Linked Lives
        </Typography>
        <Typography variant="body2">Mobile: (360) 336-6344</Typography>
      </Box>
      <Call sx={{ ml: "auto", mr: 2 }} />
    </ButtonBase>
  );

  const renderBBB = () => {
    return (
      <Box
        component={"a"}
        href={
          "https://www.bbb.org/us/wa/anacortes/profile/home-services/linked-lives-1296-1000171080"
        }
        target={"_blank"}
        rel={"noopener noreferrer"}
        sx={{
          display: "block",
          width: "150px",
          height: "auto",
          mr: "auto",
          ml: { xs: 0, sm: "auto" },
        }}
      >
        <Box
          component="img"
          src={bbbBadge}
          sx={{ width: "100%", height: "auto", mx: "auto" }}
        />
      </Box>
    );
  };

  // Define the benefits for each plan

  return (
    <Box sx={{ backgroundColor: "#ffffff" }}>
      <Box
        sx={{
          pt: { xs: 2, sm: 4 },
          pb: { xs: 3, sm: 4 },
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          margin: "auto", // Centers the box while maintaining a max width
          maxWidth: 960, // Max width of the text box
          paddingX: theme.spacing(2), // Horizontal padding
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 2,
            }}
          >
            <Box
              sx={{
                maxWidth: { xs: "425px", sm: "960px" },
                mx: "auto",
                position: "relative",
                display: "flex",
                flexDirection: "column",
                p: { xs: 1, sm: 2 },
                boxSizing: "border-box",
                background: "white",
                border: "1px solid rgba(0, 0, 0, 0.23)",
                borderRadius: "15px",
                boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.3)", // Custom shadow
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  flexDirection: { xs: "column", sm: "row" },
                  gap: 6,
                  p: { xs: 2, sm: 4 },
                  boxSizing: "border-box",
                }}
              >
                <Box
                  sx={{
                    flex: 0.6,
                    minWidth: { xs: "auto", sm: "350px" },
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                    }}
                  />

                  <Typography
                    zIndex={"30"}
                    position={"relative"}
                    variant="h4"
                    sx={{ fontSize: { xs: "26px", sm: "36px" } }}
                  >
                    {/* View Local Pricing */}
                    {/* Contact us */}
                    Prefer a Call?
                  </Typography>
                  <Typography
                    zIndex={"30"}
                    position={"relative"}
                    sx={{ mb: { xs: 0, sm: 0, fontStyle: "italic" } }} //
                  >
                    {/* Please share your details to view our best hourly rate for
                    your zip code and get your first hour free. */}
                    {/* Please share your details to view our pricing for your zip
                    code. We will follow up to see how we can help. */}
                    We love connecting over the phone. After
                    answering your questions, we can setup your account and
                    schedule your first visit if you're ready. We look forward
                    to meeting you!
                  </Typography>
                  {isMobile && (
                    <Box sx={{ my: 1 }}>
                      {renderBBB()}

                      {mode === "benefits" && renderBenefits()}
                    </Box>
                  )}

                  {/* <TaskCheckboxes
                    tasks={tasks}
                    values={values}
                    handleChange={handleChange}
                  /> */}
                  <TextField
                    name="name" // Add this line
                    id="name"
                    label="Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={values.name}
                    onChange={handleChange}
                    error={!!errors.name} // Show error state if there's an error for zipCode
                    helperText={errors.name} // Display the actual error message
                  />
                  <TextField
                    multiline
                    minRows={1}
                    fullWidth
                    variant="outlined"
                    name="message"
                    sx={{ mt: 1 }}
                    label="Leave a message..."
                    value={values.message}
                    onChange={handleChange}
                  />

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column-reverse", sm: "row" },
                      gap: 2,
                      mt: 1,
                    }}
                  >
                    <TextField
                      name="zipCode"
                      id="zipCode"
                      label="Zip Code"
                      type="tel" // Encourages a numeric keypad
                      inputMode="numeric" // Suggests a numeric keyboard on devices that support inputMode
                      fullWidth
                      variant="outlined"
                      value={values.zipCode}
                      onChange={handleZipChange}
                      error={!!errors.zipCode} // Show error state if there's an error for zipCode
                      helperText={errors.zipCode} // Display the actual error message
                    />

                    <PhoneField
                      phone={values.phone}
                      error={errors.phone}
                      handleChange={handleChange}
                    />
                  </Box>
                  <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    disabled={loading}
                    onClick={(e) => handleSave(e, true)}
                    sx={{ height: "56px", mt: 1, textTransform: "none", mb: 1 }}
                  >
                    {loading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      // "Get my free hour and view rates"
                      // "View pricing and personal follow up"
                      "Submit"
                    )}
                  </Button>
                  {/* <Button
                    variant="text"
                    size="large"
                    fullWidth
                    disabled={loading}
                    onClick={(e) => handleSkip(e, false)}
                    sx={{ height: "56px", textTransform: "none" }}
                  >
                    {loading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      // "Skip my free hour, just view rates"
                      "Skip follow up, just view pricing"
                    )}
                  </Button> */}
                  {!isMobile && renderBBB()}

                  {/* {renderBBB()} */}
                  {/* {renderOwner()} */}

                  {/* <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 1,
                      alignItems: "center",
                      color: "#999999",
                      mt: 1,
                    }}
                  >
                    <Typography variant="caption" align="center">
                      By submitting, I agree to receive text messages and calls
                      from Linked Lives, of which I can opt-out at any time.
                    </Typography>
                  </Box> */}
                  {/* <Divider sx={{ my: 1 }}>or</Divider>

                <Button
                  fullWidth
                  variant="outlined"
                  size="large"
                  component="a"
                  href="tel:+13603366344"
                  sx={{
                    width: "100%",
                    height: { xs: "60px" },
                    textTransform: "none",
                  }}
                  startIcon={<Call />}
                >
                  Call (360) 336-6344
                </Button> */}
                </Box>
                {!isMobile && (
                  <Box
                    sx={{
                      flex: 0.5,
                      maxWidth: "400px",
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                    }}
                  >
                    {mode === "benefits"
                      ? renderBenefits()
                      : mode === "reviews" && (
                          <VisitReviewCarousel START_LIMIT={10} />
                        )}
                    {/* {renderBBB()} */}
                    {/* {renderOwner()} */}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        {mode === "reviews" && isMobile && (
          <VisitReviewCarousel START_LIMIT={10} />
        )}
      </Box>
    </Box>
  );
}

export default IntakeForm;
