import { Phone } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import coverPhoto from "../../../assets/local-senior-help-linked-lives.jpeg";

function LandingPage({ city = "", state = "" }) {
  const theme = useTheme(); // hook to get current theme
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  console.log("--- City: ", city);

  return (
    <div>
      {/* The background image */}
      {/* Only show the card over the image if it's NOT mobile */}
      {!isMobile && (
        <div
          style={{
            backgroundImage: `url(${coverPhoto})`,
            backgroundSize: "cover",
            backgroundPosition: `20% center`, // Update background position based on translateValue
            backgroundRepeat: "no-repeat",
            height: "500px",
            position: "relative", // To position the card over the image on desktop
            width: "100%", // Make sure the width is 100% of the viewport
          }}
        >
          <Container style={{ position: "relative", height: "100%" }}>
            <Card
              style={{
                backgroundColor: "hsla( 192,100%,97.06% ,0.8)",
                backdropFilter: "blur(15px)",
                borderRadius: "15px",
                position: "relative",
                top: "50%",
                left: "50%",
                transform: "translatey(-50%)", // Centering both horizontally and vertically
                width: "48%",
                padding: "24px",
              }}
            >
              <CardContent>
                <Typography
                  component="h1" // This changes the underlying element to an h1
                  variant="h3"
                  fontWeight="700"
                  gutterBottom
                >
                  {/* {city ? `Senior Household Help ` : "Senior Household Help "} */}
                  Senior Household Help{" "}
                  <span
                    style={{
                      color: theme.palette.primary.main,
                      fontStyle: "italic",
                    }}
                  >
                    on demand
                    {/* {state ? ` in ${state}` : "on demand"} */}
                  </span>
                </Typography>
                <Typography variant="body1" sx={{ my: 2 }}>
                  Enjoy peace of mind as our trusted helpers tackle your to-do
                  list. We complete tasks you don't want to do, can't do, or
                  need an extra hand with - anything from weeding to
                  organization, lifting to tech help, and much more. We're here
                  to serve!
                </Typography>
                <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                  {/* <Button
                    onClick={() => navigate("/get-started")} // Add navigation function
                    disableElevation
                    startIcon={<Event />}
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{
                      textTransform: "none",
                    }}
                  >
                    Create account
                  </Button> */}
                  <Button
                    component="a"
                    disableElevation
                    href="tel:+13603366344"
                    startIcon={<Phone />}
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{
                      textTransform: "none",
                    }}
                  >
                    Give us a call
                  </Button>
                  {/* <Button
                    onClick={() => navigate("/services")} // Add navigation function
                    disableElevation
                    // startIcon={<Payments />}
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{
                      textTransform: "none",
                    }}
                  >
                    Services
                  </Button>
                  <Button
                    onClick={() => navigate("/reviews")} // Add navigation function
                    disableElevation
                    // startIcon={<Payments />}
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{
                      textTransform: "none",
                    }}
                  >
                    Reviews
                  </Button>
                  <Button
                    onClick={() => navigate("/pricing")} // Add navigation function
                    disableElevation
                    // startIcon={<Payments />}
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{
                      textTransform: "none",
                    }}
                  >
                    Pricing
                  </Button> */}
                </Box>
              </CardContent>
            </Card>
          </Container>
        </div>
      )}

      {/* Only show the card below the image if it's mobile */}
      {isMobile && (
        <Box>
          {/* <div
            style={{
              position: "relative",
              width: "100%",
              paddingBottom: "56.25%", // Aspect ratio of 16:9. Adjust this for different ratios if needed.
              marginTop: "10px",
              // overflowY: "hidden",
            }} */}

          <div
            style={{
              position: "relative",
              width: "100vw", // Full viewport width
              overflow: "hidden", // Ensures no overflow
              paddingBottom: "56.25%", // Aspect ratio of 16:9
              marginTop: "0px",
            }}
          >
            <img
              src={coverPhoto}
              alt="Descriptive alt text"
              style={{
                position: "absolute",
                top: "0%",
                left: "-20%",
                width: "180%",
                height: "120%",
                objectFit: "cover",
                objectPosition: "20% center",
              }}
            />
          </div>
          <Card
            elevation={0}
            style={{
              backgroundColor: "hsla( 192,100%,97.06% ,0.8)",
              backdropFilter: "blur(15px)",
              // borderRadius: "15px",
              // marginBottom: "-20px",
              // marginTop: "-20px",
            }}
          >
            <CardContent>
              <Typography variant="h4" fontWeight="700">
                {/* {city ? `Senior Household Help ` : "Local Senior Help "} */}
                Senior Household Help{" "}
                <span
                  style={{
                    color: theme.palette.primary.vibrant,
                    fontStyle: "italic",
                  }}
                >
                  {/* {city ? ` in ${city}` : "on demand"} */}
                  on demand
                  {/* with 100s of Tasks */}
                </span>
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Enjoy peace of mind as our trusted helpers tackle your to-do
                list. We complete tasks you don't want to do, can't do, or need
                an extra hand with - anything from weeding to organization,
                lifting to tech help, and much more. We're here to serve!
              </Typography>
              <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
                {/* <Button
                  onClick={() => navigate("/get-started")} // Add navigation function
                  disableElevation
                  startIcon={<Event />}
                  variant="contained"
                  color="primary"
                  size="large"
                  sx={{
                    textTransform: "none",
                    height: "56px",
                    width: "100%",
                  }}
                >
                  Create account
                </Button> */}
                <Box sx={{ width: "100%", gap: 1, display: "flex" }}>
                  <Button
                    onClick={() => navigate("/services")} // Add navigation function
                    disableElevation
                    // startIcon={<Payments />}
                    variant="outlined"
                    color="primary"
                    size="large"
                    sx={{
                      textTransform: "none",
                      height: "56px",
                      width: "33%",
                    }}
                  >
                    Services
                  </Button>
                  <Button
                    onClick={() => navigate("/reviews")} // Add navigation function
                    disableElevation
                    // startIcon={<Payments />}
                    variant="outlined"
                    color="primary"
                    size="large"
                    sx={{
                      textTransform: "none",
                      height: "56px",
                      width: "33%",
                    }}
                  >
                    Reviews
                  </Button>
                  <Button
                    onClick={() => navigate("/pricing")} // Add navigation function
                    disableElevation
                    // startIcon={<Payments />}
                    variant="outlined"
                    color="primary"
                    size="large"
                    sx={{
                      textTransform: "none",
                      height: "56px",
                      width: "33%",
                    }}
                  >
                    Pricing
                  </Button>
                </Box>

                <Button
                  component="a"
                  href="tel:+13603366344"
                  startIcon={<Phone />}
                  variant="outlined"
                  color="primary"
                  size="large"
                  sx={{
                    textTransform: "none",
                    height: "56px",
                    width: "100%",
                  }}
                >
                  Give us a call
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Box>
      )}
    </div>
  );
}

export default LandingPage;
