import { useEffect, useRef } from "react";
import { useSession } from "../providers/SessionProvider";

const useTrackVisibility = (callback, logPath) => {
  const ref = useRef(null);
  const { logSessionActivity } = useSession();

  useEffect(() => {
    const observer = new IntersectionObserver(
      async ([entry]) => {
        if (entry.isIntersecting) {
          if (logPath) {
            await logSessionActivity(`Scrolled to ${logPath}`); // Log to Firebase
          }
          if (callback) {
            callback();
          }
        }
      },
      {
        root: null,
        rootMargin: "-30% 0px -70% 0px", // Move the top and bottom boundaries to the middle of the viewport
        threshold: 0, // Trigger when any part of the element crosses this boundary
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [callback, logPath]);

  return ref;
};

export default useTrackVisibility;
