{
  /* <iframe src="https://docs.google.com/document/d/e/2PACX-1vS4xC1rGCLJIYskMvNjdaGT6qYs9oI056Imk7kJd8I50WkIJkUgt7ZV38jfbn4ezEPuKmazwDFDxMgX/pub?embedded=true"></iframe> */
}

// src/data/legalDocuments.js
export const legalDocuments = [
  {
    slug: "terms-of-service",
    title: "Terms of Service (ToS)",
    url: "https://docs.google.com/document/d/e/2PACX-1vTAZ7vfXaIEA-IUBa3udVAVQJeScheD86TRAkVYxHv2ud_W2Rw44lJb-_knlRshmU-1aD3pqBHHZ0ew/pub?embedded=true",
  },
  {
    slug: "privacy-policy",
    title: "Privacy Policy",
    url: "https://docs.google.com/document/d/e/2PACX-1vTH_KbkPzLmn3ENb2wiuDWXVbGT4MGZQCtiEo1jQ124jubNyiAWn7edVCFQZqFOI9ji-9vI-IGnIPqd/pub?embedded=true",
  },
  {
    slug: "code-of-conduct",
    title: "Code of Conduct",
    url: "https://docs.google.com/document/d/e/2PACX-1vTkUloc9Cn8VJ_4azgPMr9QGKlsjRUihCxDVRkib2Aq_wXe2SRfPt2ibq57jjzgKCMyiY-uuCPjr5Se/pub?embedded=true",
  },
  {
    slug: "safety-policy",
    title: "Safety Policy",
    url: "https://docs.google.com/document/d/e/2PACX-1vRA-dQ1W4NRCnoGQpBhGXtWAhdHHy79JpUzzMrPIPfbL6X5aW7XQZwT3C1Y4Gf_bB4p50nm8JXyvw0O/pub?embedded=true",
  },
  {
    slug: "cancellation-and-refund-policy",
    title: "Cancellation and Refund Policy",
    url: "https://docs.google.com/document/d/e/2PACX-1vQU-O2jGIsc3GMcJp7XvD_ycAb-KFpBE0YuNZIQzG0uz9jW0fEnW5BJWpeUNt2S0r7eNH2kS7ca12Jn/pub?embedded=true",
  },
  {
    slug: "dispute-resolution-agreement",
    title: "Dispute Resolution Agreement",
    url: "https://docs.google.com/document/d/e/2PACX-1vTUrdqWVoIEAXSudOEEkPIlOaHufmxxM-ymzW6Pt6O_px_xkiPxm_mk0sQm0gxU5iyPkWIcn-952jw6/pub?embedded=true",
  },
  {
    slug: "independent-contractor-agreement",
    title: "Independent Contractor Agreement",
    url: "https://docs.google.com/document/d/e/2PACX-1vS4xC1rGCLJIYskMvNjdaGT6qYs9oI056Imk7kJd8I50WkIJkUgt7ZV38jfbn4ezEPuKmazwDFDxMgX/pub?embedded=true", // Replace with your doc link
  },
  {
    slug: "general-liability-waiver",
    title: "General Liability Waiver",
    url: "https://docs.google.com/document/d/e/2PACX-1vTMM7je1rAYKCFcSBL4_ZTZvcveLExobDlUUnCFNGvqQ1lt0G8ohWXVMBE2_8fN2C4DWVFQRGTrrj7e/pub?embedded=true",
  },
  {
    slug: "link-handbook",
    title: "Link Handbook",
    url: "https://docs.google.com/document/d/e/2PACX-1vSYE5X8se0qZKG0lT7A0m1LbYduAoiddTEoaLpuccEEvZY9g8OXgLhists0ayYu7LsaGeE1f7xh6kON/pub?embedded=true"
  }
];

