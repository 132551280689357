import { useTheme } from "@emotion/react";
import { Check, People, Public } from "@mui/icons-material";
import {
  Button,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import ResponsiveDialog from "../dialogs/ResponsiveDialog";

const VisibilitySelector = ({ isPublic, setIsPublic }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null); // For positioning the menu
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setTimeout(() => {
      setAnchorEl(null);
      setOpen(false);
    }, 250); // Delay to allow for smooth closing
  };

  const handleVisibilityChange = (newVisibility) => {
    setIsPublic(newVisibility);
    handleClose();
  };

  const renderSelect = () => (
    <List disablePadding>
      <ListItemButton onClick={() => handleVisibilityChange(true)}>
        <ListItemIcon sx={{ minWidth: "44px" }}>
          <Public color="primary" sx={{ fontSize: "1.8rem" }} />
        </ListItemIcon>
        <ListItemText
          primary="Public"
          secondary="Visit description is visible to others on the platform."
        />
        <Check sx={{ ml: 2, opacity: isPublic ? 1 : 0 }} />
      </ListItemButton>
      <ListItemButton onClick={() => handleVisibilityChange(false)}>
        <ListItemIcon sx={{ minWidth: "44px" }}>
          <People color="primary" sx={{ fontSize: "1.8rem" }} />
        </ListItemIcon>
        <ListItemText
          primary="Private"
          secondary="Visit description is only visible to your helper."
        />
        <Check sx={{ ml: 2, opacity: !isPublic ? 1 : 0 }} />
      </ListItemButton>
    </List>
  );

  return (
    <>
      <Button
        onClick={handleClick}
        sx={{
          cursor: "pointer",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          textTransform: "none",
          gap: "6px",
          width: "max-content",
          mr: "auto",
          color: "text.secondary",
          my: 1,
        }}
      >
        {isPublic ? (
          <>
            <Public fontSize="small" /> Public
          </>
        ) : (
          <>
            <People /> Private
          </>
        )}
      </Button>
      {isMobile ? (
        <ResponsiveDialog
          open={open}
          onClose={handleClose}
          title="Description visibility"
          width="600px"
          anchor="bottom"
          zIndex={2100}
        >
          {renderSelect()}
          <div style={{ height: "60px" }} />
        </ResponsiveDialog>
      ) : (
        <Menu
          id="visibility-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "visibility-button",
          }}
        >
          {renderSelect()}
        </Menu>
      )}
    </>
  );
};

export default VisibilitySelector;
