import {
  collection,
  getCountFromServer,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { DateTime } from "luxon";
import React, { createContext, useContext, useEffect, useState } from "react";
import { db } from "../firebase"; // Assuming you have Firebase initialized here
import { useAuth } from "./AuthProvider";

// Create the context
const CRMContext = createContext();

// Calculate the date for one month ago using Luxon
const oneMonthAgo = DateTime.now().minus({ months: 1 }).toJSDate();
const endOfToday = DateTime.now().endOf("day").toJSDate();
const startOfToday = DateTime.now().startOf("day").toJSDate();

// Calculate the start and end of the current month using Luxon
const startOfCurrentMonth = DateTime.now().startOf("month").toJSDate();
const endOfCurrentMonth = DateTime.now().endOf("month").toJSDate();
const currentMonth = DateTime.now().toFormat("MMMM"); // Get the current month's name (e.g., "October")

// Provider component to manage all the tab data
export const CRMProvider = ({ children }) => {
  const [tabData, setTabData] = useState({});
  const [selectedItem, setSelectedItem] = useState(null);
  const { currentUser } = useAuth();
  const userId = currentUser?.uid; // Get the current authenticated user

  // Config mapping each tab to its Firestore query and label
  const tabsConfig = [
    ///////////////////////////// MEMBERS /////////////////////////////

    {
      label: "New Members",
      key: "New Members",
      collection: "membersPrivate",
      query: () =>
        query(
          collection(db, "membersPrivate"),
          where("crm.lastContactedDate", "==", false),
          orderBy("crm.followUpDate", "desc") // DESC - most recent first
        ),
    },
    {
      label: "Follow Up Members",
      key: "Follow Up Members",
      collection: "membersPrivate",
      query: () =>
        query(
          collection(db, "membersPrivate"),
          where("crm.followUpDate", "<=", endOfToday),
          where("crm.lastContactedDate", "<=", endOfToday),
          orderBy("crm.followUpDate", "desc") // DESC - most recent first
        ),
    },
    {
      label: "Member History",
      key: "Member History",
      collection: "membersPrivate",
      query: () =>
        query(
          collection(db, "membersPrivate"),
          where("crm.lastViewed.id", "==", userId),
          orderBy("crm.lastViewed.timestamp", "desc") // DESC - most recent first
        ),
    },
    {
      label: "Active Members",
      key: "Active Members",
      collection: "membersPrivate",
      query: () =>
        query(
          collection(db, "membersPrivate"),
          orderBy("admin.lastActive", "desc") // DESC - most recent first
        ),
    },

    ///////////////////////////// VISITS /////////////////////////////
    {
      label: "Upcoming Visits",
      key: "Upcoming Visits",
      collection: "visits",
      query: () =>
        query(
          collection(db, "visits"),
          where("status", "==", "confirmed"),
          where("start", ">=", startOfToday),
          orderBy("start", "asc") // ASC - earliest first
        ),
    },
    {
      label: "Recent Visits",
      key: "Recent Visits",
      collection: "visits",
      query: () =>
        query(
          collection(db, "visits"),
          where("status", "==", "confirmed"),
          where("start", "<=", endOfToday),
          where("start", ">=", oneMonthAgo),
          orderBy("start", "desc") // DESC - most recent first
        ),
    },
    {
      label: `${currentMonth} Visits`, // Dynamically set the label with the current month
      key: `${currentMonth} Visits`, // Use the same for the key
      collection: "visits",
      query: () =>
        query(
          collection(db, "visits"),
          where("status", "==", "confirmed"),
          where("start", ">=", startOfCurrentMonth),
          where("start", "<=", endOfCurrentMonth),
          orderBy("start", "asc") // ASC - earliest first
        ),
    },
    // {
    //   label: "Created Visits",
    //   key: "Created Visits",
    //   collection: "visits",
    //   query: () =>
    //     query(
    //       collection(db, "visits"),
    //       where("status", "==", "confirmed"),
    //       where("created", ">=", oneMonthAgo),
    //       orderBy("created", "desc") // DESC - most recent first
    //     ),
    // },
    {
      label: "Open Visits",
      key: "Open Visits",
      collection: "visits",
      query: () =>
        query(
          collection(db, "visits"),
          where("isOpenVisit", "==", true),
          where("status", "==", "confirmed"),
          orderBy("start", "desc") // DESC - most recent first
        ),
    },
    // {
    //   label: "Reschedule Visits",
    //   key: "Reschedule Visits",
    //   collection: "visits",
    //   query: () =>
    //     query(
    //       collection(db, "visits"),
    //       where("status", "==", "reschedule"),
    //       orderBy("start", "desc") // DESC - most recent first
    //     ),
    // },
    // {
    //   label: "Cancelled Visits",
    //   key: "Cancelled Visits",
    //   collection: "visits",
    //   query: () =>
    //     query(
    //       collection(db, "visits"),
    //       where("status", "==", "cancelled"),
    //       orderBy("start", "desc") // DESC - most recent first
    //     ),
    // },
    ///////////////////////////// EMPLOYEES /////////////////////////////
    {
      label: "All employees",
      key: "All employees",
      collection: "employeesPrivate",
      query: () =>
        query(
          collection(db, "employeesPrivate"),
          orderBy("firstName", "asc") // ASC - A's first
        ),
    },
    {
      label: "Applicants",
      key: "Applicants",
      collection: "employeesPrivate",
      query: () =>
        query(
          collection(db, "employeesPrivate"),
          where("status", "==", "applicant"),
          orderBy("created", "desc") // DESC - most recent first
        ),
    },
    {
      label: "Interviews",
      key: "Interviews",
      collection: "employeesPrivate",
      query: () =>
        query(
          collection(db, "employeesPrivate"),
          where("status", "==", "interview"),
          orderBy("created", "desc") // DESC - most recent first
        ),
    },
    {
      label: "Onboarding",
      key: "Onboarding",
      collection: "employeesPrivate",
      query: () =>
        query(
          collection(db, "employeesPrivate"),
          where("status", "==", "onboarding"),
          orderBy("created", "desc") // DESC - most recent first
        ),
    },
    ///////////////////////////// MEMBERS /////////////////////////////

    {
      label: "Recent Sessions",
      key: "Recent Sessions",
      collection: "sessions",
      query: () =>
        query(
          collection(db, "sessions"),
          orderBy("lastVisited", "desc") // DESC - most recent first
        ),
    },
    ///////////////////////////// LEADS /////////////////////////////
    {
      label: "New Leads",
      key: "New Leads",
      collection: "leads",
      query: () =>
        query(
          collection(db, "leads"),
          where("crm.lastContactedDate", "==", false),
          orderBy("crm.followUpDate", "desc") // DESC - most recent first
        ),
    },
    {
      label: "Follow Up Leads",
      key: "Follow Up Leads",
      collection: "leads",
      query: () =>
        query(collection(db, "leads"), orderBy("crm.followUpDate", "desc")), // DESC - most recent first
    },
    {
      label: "Lead History",
      key: "Lead History",
      collection: "leads",
      query: () =>
        query(
          collection(db, "leads"),
          where("lastViewed.id", "==", userId),
          orderBy("lastViewed.timestamp", "desc") // DESC - most recent first
        ),
    },
  ];

  console.log("tabData: ", tabData);

  const selectItem = (item, tabKey) => {
    console.log("item, tabKey: ", { ...item, tabKey });

    // Store both the item and the tabKey for lookup
    setSelectedItem({ ...item, tabKey });
  };

  // Fetch counts for all tabs on initial load
  const fetchCountsForTabs = async () => {
    const counts = {};
    for (const tab of tabsConfig) {
      try {
        const countSnapshot = await getCountFromServer(tab.query());
        counts[tab.key] = countSnapshot.data().count;
        console.log(`Count for ${tab.key}:`, countSnapshot.data().count);
      } catch (error) {
        // Log the error for this specific tab, but continue with others
        console.error(`Error fetching count for ${tab.key}:`, error);
        counts[tab.key] = "Error"; // Optionally, store an error indicator
      }
    }

    // Update the tab data with the counts, even if some failed
    setTabData((prev) => ({
      ...prev,
      counts, // Store counts separately
    }));
  };

  // Call fetchCountsForTabs when the provider component mounts
  useEffect(() => {
    fetchCountsForTabs();
    loadTabData(tabsConfig[0].key); // populate the first tab on load
  }, []);

  const START_LIMIT = 10;

  const loadTabData = async (tabKey) => {
    const selectedTab = tabsConfig.find((tab) => tab.key === tabKey);
    if (!selectedTab) return;

    // Set loading state to true at the start of fetching
    setTabData((prev) => ({
      ...prev,
      [tabKey]: {
        ...prev[tabKey],
        loading: true, // Initial load state set to true
      },
    }));

    // await new Promise((resolve) => setTimeout(resolve, 1200));

    // Refresh tab counts for all tabs when a tab is clicked
    fetchCountsForTabs();

    try {
      // Build the query with the limit
      const queryWithLimit = query(selectedTab.query(), limit(START_LIMIT));

      // Fetch the documents once
      const querySnapshot = await getDocs(queryWithLimit);

      // Map the documents to your desired format
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        collection: selectedTab.collection,
        ...doc.data(),
      }));

      // Update the tab data with the fetched data
      setTabData((prev) => ({
        ...prev,
        [tabKey]: {
          data,
          hasMore: querySnapshot.size === START_LIMIT,
          lastVisible: querySnapshot.docs[querySnapshot.docs.length - 1],
          collection: selectedTab.collection,
          loading: false, // Reset loading state
        },
      }));
    } catch (error) {
      // Handle any errors and update the tab data with the error message
      setTabData((prev) => ({
        ...prev,
        [tabKey]: { error: error.message, loading: false }, // Reset loading on error
      }));
    }
  };

  const loadMoreForTab = async (tabKey, COUNT = 10) => {
    const selectedTab = tabsConfig.find((tab) => tab.key === tabKey);

    // Check if the selected tab exists, and also check if there's more data to load
    if (
      !selectedTab ||
      !tabData[tabKey]?.lastVisible ||
      tabData[tabKey]?.loading
    )
      return;

    try {
      // Set loading state to true
      setTabData((prev) => ({
        ...prev,
        [tabKey]: {
          ...prev[tabKey],
          loading: true, // Mark as loading
        },
      }));

      // await new Promise((resolve) => setTimeout(resolve, 100));

      // Build the next query for loading more data
      const nextQuery = query(
        selectedTab.query(),
        startAfter(tabData[tabKey].lastVisible),
        limit(COUNT)
      );

      // Fetch the next batch of documents
      const querySnapshot = await getDocs(nextQuery);

      // Map the documents to your desired format
      const moreData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        collection: selectedTab.collection,
        ...doc.data(),
      }));

      // Update the tab data and reset the loading state
      setTabData((prev) => ({
        ...prev,
        [tabKey]: {
          data: [...prev[tabKey].data, ...moreData],
          hasMore: querySnapshot.size === COUNT,
          lastVisible: querySnapshot.docs[querySnapshot.docs.length - 1],
          // loading: false, // Loading complete
          collection: selectedTab.collection,
        },
      }));
    } catch (error) {
      // Handle error and reset the loading state
      setTabData((prev) => ({
        ...prev,
        [tabKey]: {
          ...prev[tabKey],
          error: error.message,
          loading: false, // Reset loading state
        },
      }));
    }
  };

  return (
    <CRMContext.Provider
      value={{
        tabData,
        loadTabData,
        loadMoreForTab,
        tabsConfig,
        selectItem,
        selectedItem,
      }}
    >
      {children}
    </CRMContext.Provider>
  );
};

// Custom hook to access the tab data
export const useCRM = () => {
  return useContext(CRMContext);
};
