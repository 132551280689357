import {
  Box,
  Button,
  Fade,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { useSnackbar } from "../../providers/SnackbarProvider";

import { EventAvailable, EventBusy } from "@mui/icons-material";
import moment from "moment-timezone";
import { useSession } from "../../providers/SessionProvider";
import BookingOptions from "./BookingOptions";
import DateDisplay from "./DateDisplay";
import DatePicker from "./DatePicker";
import VisitDisplay from "./VisitDisplay";

const VisitPicker = ({
  onSelectSlot = () => {},
  visits,
  selectedVisit,
  setSelectedVisit,
  setFilters,
  filters,
  employees,
  timeZoneId,
  memberId,
  parentSelectedDate = null,
  bestMatchEmployee = null,
  adminMode = false,
  isOpen = true,
}) => {
  const { showSnackbar } = useSnackbar();
  const { createLog } = useSession();
  const [isStacked, setIsStacked] = useState(false);
  const containerRef = useRef(null);

  console.log("VisitPicker - isStacked: ", isStacked);

  useEffect(() => {
    const checkStacked = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        console.log("VisitPicker - containerWidth: ", containerWidth);
        setIsStacked(containerWidth < 700); // Set to true if width is less than 500px
      }
    };

    const delayedCheck = () => {
      console.log("VisitPicker - delayedCheck",);
      setTimeout(checkStacked, 1000); // Add a 300ms delay
    };

    delayedCheck(); // Initial check with delay

    checkStacked(); // Initial check

    window.addEventListener("resize", checkStacked); // Listen for window resize

    return () => {
      window.removeEventListener("resize", checkStacked); // Cleanup listener
    };
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Additional states for selected date, filters, and available visits
  const [selectedDate, setSelectedDate] = useState(
    parentSelectedDate
      ? moment(parentSelectedDate).tz(timeZoneId).format("YYYY-MM-DD")
      : null
  );
  const [filteredVisits, setFilteredVisits] = useState([]); // filtered visits

  const [availableVisits, setAvailableVisits] = useState([]);
  const [fadeIn, setFadeIn] = useState(false);

  console.log("selectedDate: ", moment(selectedDate).tz(timeZoneId));

  useEffect(() => {
    const timer = setTimeout(() => {
      setFadeIn(true);
    }, 50);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (filteredVisits.length > 0 && availableVisits.length === 0) {
      setSelectedDate(null);
    }
  }, [availableVisits]);

  // If we have selected a day (no visit yet) and that day is no longer available
  // OR If we have selected a visit and that visit is no longer available
  useEffect(() => {
    if (!selectedDate) return;

    console.log("selectedDate: ", selectedDate);

    // Check if there are no more visits on the previously selected day
    const selectedDay = moment(selectedDate).tz(timeZoneId);
    console.log("selectedDay: ", selectedDay.format("MM-DD hh:mm a"));
    const visitsOnSelectedDay = visits?.filter((visit) => {
      // console.log(
      //   "moment(visit.start).tz(timeZoneId): ",
      //   moment(visit.start).tz(timeZoneId).format("MM-DD hh:mm a")
      // );

      const result = moment(visit.start)
        .tz(timeZoneId)
        .isSame(selectedDay, "day");

      // console.log("result: ", result);
      return result;
    });

    if (visitsOnSelectedDay.length === 0) {
      console.log("there are no visits on the selected day");
      setSelectedDate(null);
    }

    if (
      isOpen &&
      selectedVisit.key &&
      !visits.some(
        (visit) =>
          generateVisitKey(memberId, visit, filters.frequency) ===
          selectedVisit.key
      )
    ) {
      setSelectedVisit({}); // Reset selectedVisit
      showSnackbar("The selected time is no longer available.", "warning");
    }
  }, [visits]);

  if (!Array.isArray(visits)) {
    console.error("Visits data is not an array or not loaded yet.");
    return null; // Or return a loading state/component
  }

  const handleDateChange = (date) => {
    setSelectedVisit({}); // Reset selectedVisit and its key
    setSelectedDate(date);

    if (!adminMode) {
      createLog({
        collectionName: "members",
        idsArr: [memberId],
        summary: `Selected a date.`,
        logType: "activity",
      });
    }
  };

  const handleTimeSlotSelect = (visit) => {
    const visitKey = generateVisitKey(memberId, visit, filters.frequency);

    setSelectedVisit({
      visit,
      key: visitKey,
    });

    if (!adminMode) {
      createLog({
        collectionName: "members",
        idsArr: [memberId],
        summary: `Selected a time slot.`,
        logType: "activity",
      });
    }
    onSelectSlot();
  };

  const generateVisitKey = (memberId, visit, frequency) => {
    return `${memberId}-${visit.employeeIds.join("-")}-${visit.start}-${
      visit.end
    }-${frequency}-${visit.duration}`;
  };

  const resetFilters = () => {
    setFilters({
      duration: 2,
      frequency: 0,
      employeeIds: [],
      numEmployees: "1",
    });
  };

  const gridSpacing = isMobile ? 0 : 2;

  return (
    <Box ref={containerRef}>
      <BookingOptions
        isStacked={isStacked}
        filters={filters}
        setFilters={setFilters}
        handleReset={resetFilters}
        visits={visits}
        employees={employees}
        bestMatchEmployee={bestMatchEmployee}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        onFilterVisits={(values) => setFilteredVisits(values)}
        onFilterVisitsDate={(values) => setAvailableVisits(values)}
      />
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 4,
          p: isStacked ? 0 : 2,
        }}
      >
        <Box
          sx={{
            flex: "1 1 calc(50% - 16px)",
            minWidth: "260px", // Minimum width for each box
          }}
        >
          {/* Calendar Component */}
          <DatePicker
            timeZoneId={timeZoneId}
            visits={filteredVisits}
            filters={filters}
            selectedDate={selectedDate}
            onChange={handleDateChange}
            allowCollapse={isStacked || isMobile}
          />
        </Box>

        <Box
          sx={{
            flex: "1 1 calc(50% - 16px)",
            minWidth: "260px", // Minimum width for each box
          }}
        >
          {selectedDate && (
            <>
              <DateDisplay selectedDate={selectedDate} />
              {availableVisits.length > 0 && (
                <VisitDisplay
                isStacked={isStacked}
                  timeZoneId={timeZoneId}
                  memberId={memberId}
                  filters={filters}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  selectedVisit={selectedVisit}
                  employees={employees}
                  availableVisits={availableVisits}
                  bestMatchEmployee={bestMatchEmployee}
                  handleTimeSlotSelect={handleTimeSlotSelect}
                />
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default VisitPicker;
