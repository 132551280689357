import React, { createContext, useContext, useState } from "react";

const DrawerContext = createContext();

export const DrawerProvider = ({ children }) => {
  const [drawers, setDrawers] = useState([]);

  console.log("DrawerProvider - drawers: ", drawers)

  const openDrawer = (component, props, onClose) => {
    setDrawers((prevDrawers) => [...prevDrawers, { component, props, onClose }]);
  };

  const handleDrawerExited = (index) => {
    console.log("handleDrawerExited")
    setDrawers((prevDrawers) => prevDrawers.filter((_, i) => i !== index));
  };

  return (
    <DrawerContext.Provider value={{ drawers, openDrawer, handleDrawerExited }}>
      {children}
    </DrawerContext.Provider>
  );
};

export const useDrawer = () => useContext(DrawerContext);
