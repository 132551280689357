// LazyEmployeeCard.js
import React, { Suspense } from "react";

const EmployeeCardV2 = React.lazy(() => import("../employee/EmployeeCardV2"));

const LazyEmployeeCard = ({
  employeeId,
  userRole = "member",
  memberId = "",
  onClose,
}) => {
  return (
    <>
      <Suspense fallback={<div>Loading Employee Card...</div>}>
        <EmployeeCardV2
          userRole={userRole}
          employeeId={employeeId}
          memberId={memberId}
          onClose={onClose}
        />
      </Suspense>
    </>
  );
};

export default LazyEmployeeCard;
