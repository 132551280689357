import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

const FooterSimple = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box display="flex" justifyContent="center" sx={{ mt: 4, mb: 1 }}>
      {isMobile ? (
        <Typography align="center" variant="body2" sx={{ opacity: 0.5 }}>
          © {new Date().getFullYear()} Linked Lives, LLC. All Rights Reserved.
          <br />
          BBB Accredited and Family Owned
        </Typography>
      ) : (
        <Typography align="center" variant="body2" sx={{ opacity: 0.5 }}>
          © {new Date().getFullYear()} Linked Lives, LLC. All Rights Reserved. • BBB Accredited and Family Owned
        </Typography>
      )}
    </Box>
  );
};

export default FooterSimple;
