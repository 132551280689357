import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Box,
  Button,
  CircularProgress,
  DialogContent,
  FormControl,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import {
  LocalizationProvider,
  MobileDatePicker,
  TimePicker,
} from "@mui/x-date-pickers";
import {
  collection,
  doc,
  getDocs,
  query,
  Timestamp,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { db } from "../../firebase";
import ResponsiveDialog from "../dialogs/ResponsiveDialog";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";

const EditDateTimeDialog = ({ open, setOpen, visitData }) => {
  const {
    id: visitId = "",
    start = Timestamp.now(),
    end = Timestamp.now(),
    timeZoneId = "America/Los_Angeles",
  } = visitData || {};

  const [loading, setLoading] = useState(false);
  const [recurrenceType, setRecurrenceType] = useState("this_visit");

  // Handle selection change for the recurrence options
  const handleRecurrenceChange = (event) => {
    setRecurrenceType(event.target.value);
  };

  // Convert Firebase Timestamps to Luxon DateTime with timeZoneId
  const [startDate, setStartDate] = useState(
    DateTime.fromJSDate(start.toDate(), { zone: timeZoneId })
  );
  const [startTime, setStartTime] = useState(
    DateTime.fromJSDate(start.toDate(), { zone: timeZoneId })
  );

  // Calculate duration in minutes between start and end using Luxon
  const [duration, setDuration] = useState(
    DateTime.fromJSDate(end.toDate(), { zone: timeZoneId }).diff(
      DateTime.fromJSDate(start.toDate(), { zone: timeZoneId }),
      "minutes"
    ).minutes
  );

  const handleSave = async () => {
    setLoading(true);

    console.log("visitId: ", visitId);

    try {
      // Convert the selected start and end times to Luxon DateTimes
      const newStart = startDate.set({
        hour: startTime.hour,
        minute: startTime.minute,
      });
      const newEnd = newStart.plus({ minutes: duration });

      if (recurrenceType === "this_visit") {
        // Update only the selected visit
        await updateDoc(doc(db, "visits", visitId), {
          start: newStart.toJSDate(),
          end: newEnd.toJSDate(),
          expiration: newStart.minus({ minutes: 30 }).toJSDate(),

          updated: Timestamp.now(),
        });
      } else if (
        recurrenceType === "this_and_future_visits" &&
        visitData?.recurrence
      ) {
        const { recurringEventId = "" } = visitData?.recurrence || {};
        const batch = writeBatch(db);

        // Calculate the consistent offset in minutes
        const baseOffsetMinutes = newStart.diff(
          DateTime.fromJSDate(start.toDate()),
          "minutes"
        ).minutes;

        // Query all future visits with the same recurringEventId
        const futureVisitsQuery = query(
          collection(db, "visits"),
          where("recurrence.recurringEventId", "==", recurringEventId),
          where("start", ">=", start)
        );

        const futureVisitsSnapshot = await getDocs(futureVisitsQuery);

        // Apply the base offset to each future visit in the sequence
        futureVisitsSnapshot.forEach((docSnapshot) => {
          const visitDoc = docSnapshot.data();
          const visitStart = DateTime.fromJSDate(visitDoc.start.toDate());
          const visitEnd = DateTime.fromJSDate(visitDoc.end.toDate());

          // Adjust start and end times by the consistent base offset
          const adjustedStart = visitStart.plus({ minutes: baseOffsetMinutes });
          const adjustedEnd = visitEnd.plus({ minutes: baseOffsetMinutes });

          // Update each visit document in the batch
          batch.update(doc(db, "visits", docSnapshot.id), {
            start: adjustedStart.toJSDate(),
            end: adjustedEnd.toJSDate(),
            expiration: adjustedStart.minus({ minutes: 30 }).toJSDate(),
            updated: Timestamp.now(),
          });
        });

        // Commit batch update to Firestore
        await batch.commit();
      }

      setOpen(false); // Close dialog after saving
    } catch (error) {
      console.error("Error updating visit(s): ", error);
    } finally {
      setLoading(false);
    }
  };

  const incrementDuration = (minutes) => {
    setDuration((prevDuration) => Math.max(15, prevDuration + minutes));
  };

  const formatDuration = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours > 0 ? `${hours}h ` : ""}${mins}m`;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <ResponsiveDialog
        open={open}
        onClose={() => setOpen(false)}
        title={`Day and Time`}
        width="400px"
        dialogActions={
          <>
            <Button
              onClick={() => setOpen(false)}
              color="primary"
              variant="text"
              disabled={loading}
              sx={{
                minWidth: { xs: "50%", sm: "150px" },
                height: { xs: "60px", sm: "auto" },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSave}
              color="primary"
              variant="contained"
              disabled={loading}
              sx={{
                minWidth: { xs: "50%", sm: "150px" },
                height: { xs: "60px", sm: "auto" },
              }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Save"
              )}
            </Button>
          </>
        }
      >
        <DialogContent>
          <Typography sx={{ mb: 2 }}>
            Adjust the date, start, and duration of the visit.
          </Typography>
          <Box
            sx={{
              p: 2,
              position: "relative",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              alignItems: "center",
            }}
          >
            <MobileDatePicker
              label="Visit Date"
              inputFormat="MM/DD/YYYY"
              value={startDate}
              onChange={(newDate) => setStartDate(newDate)}
              renderInput={(params) => (
                <TextField {...params} fullWidth sx={{ mb: 2 }} />
              )}
              sx={{ maxWidth: "200px" }}
            />
            <TimePicker
              label="Start Time"
              minutesStep={5}
              value={startTime}
              onChange={(newTime) => setStartTime(newTime)}
              renderInput={(params) => (
                <TextField {...params} fullWidth sx={{ mb: 2 }} />
              )}
              sx={{ maxWidth: "200px" }}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 16,
              }}
            >
              <IconButton
                onClick={() => incrementDuration(-15)}
                color="primary"
                disabled={duration <= 15} // Prevent going below 15 minutes
              >
                <RemoveIcon />
              </IconButton>

              <TextField
                label="Duration"
                value={formatDuration(duration)} // Use formatted duration string
                fullWidth
                InputProps={{
                  readOnly: true, // Make it read-only to avoid raw number input
                }}
                sx={{ maxWidth: "200px" }}
              />

              <IconButton onClick={() => incrementDuration(15)} color="primary">
                <AddIcon />
              </IconButton>
            </div>
            {visitData?.recurrence && (
              <FormControl
                sx={{ width: "100%", mb: "16px", borderRadius: "15px" }}
                component="fieldset"
              >
                <RadioGroup
                  name="recurrenceType"
                  value={recurrenceType}
                  onChange={handleRecurrenceChange}
                >
                  <List sx={{ py: 0 }}>
                    <ListItemButton
                      fullWidth
                      selected={recurrenceType === "this_visit"}
                      onClick={() => setRecurrenceType("this_visit")}
                      sx={{ borderRadius: "15px" }}
                    >
                      <Radio
                        checked={recurrenceType === "this_visit"}
                        value="this_visit"
                      />
                      <ListItemText primary="This visit" />
                    </ListItemButton>

                    <ListItemButton
                      fullWidth
                      selected={recurrenceType === "this_and_future_visits"}
                      onClick={() =>
                        setRecurrenceType("this_and_future_visits")
                      }
                      sx={{ borderRadius: "15px" }}
                    >
                      <Radio
                        checked={recurrenceType === "this_and_future_visits"}
                        value="this_and_future_visits"
                      />
                      <ListItemText primary="This and following visits" />
                    </ListItemButton>
                  </List>
                </RadioGroup>
              </FormControl>
            )}
          </Box>
        </DialogContent>
      </ResponsiveDialog>
    </LocalizationProvider>
  );
};

export default EditDateTimeDialog;
