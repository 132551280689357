import { Chip } from "@mui/material";
import { alpha } from "@mui/material/styles";
import React from "react";

const StyledChip = ({
  label,
  color = "primary",
  icon = null,
  size = "small",
}) => {
  let styles = {};

  // Use switch-case to define styles based on size
  switch (size) {
    case "small":
      styles = {
        borderRadius: "5px",
        fontSize: "10px",
        height: "18px",
        px: "4px",
        ml: "6px",
        mb: "2px",
      };
      break;

    case "normal":
      styles = {
        borderRadius: "5px",
        fontSize: "14px",
        height: "32px",
        px: "8px",
        mb: "2px",
      };
      break;

    case "large":
      styles = {
        borderRadius: "10px",
        fontSize: "16px",
        height: "40px",
        px: 3,
        ml: 1.5,
        mb: 0.5,
      };
      break;

    default:
      styles = {
        borderRadius: "8px",
        fontSize: "14px",
        height: "32px",
        px: 2,
        ml: 1,
        mb: 0.5,
      };
      break;
  }

  return (
    <Chip
      variant="outlined"
      label={label}
      color={color}
      icon={icon}
      sx={{
        ...styles,

        textTransform: "none",

        "& .MuiChip-label": {
          padding: "0px", // Target the label padding
        },

        backgroundColor: (theme) =>
          alpha(theme.palette[color]?.light || theme.palette.grey[200], 0.1),
      }}
    />
  );
};

export default StyledChip;
