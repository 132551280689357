import {
  Timestamp,
  collection,
  doc,
  endAt,
  endBefore,
  getDoc,
  increment,
  onSnapshot,
  orderBy,
  query,
  startAt,
  updateDoc,
  where,
} from "firebase/firestore";
import { DateTime } from "luxon"; // Import Luxon for date manipulation
import moment from "moment-timezone";
import React, { createContext, useContext, useEffect, useState } from "react";
import LoadingUser from "../components/LoadingUser";
import { db } from "../firebase";
import {
  decodeGeoHash,
  getEmployeeStatus,
  groupGeohashBounds,
} from "../services/locationServices";
import { useAuth } from "./AuthProvider";

const EmployeeContext = createContext();

const EmployeeProvider = ({ children }) => {
  const { currentUser } = useAuth();

  const [employeeData, setEmployeeData] = useState({});
  const [loadingEmployeeData, setLoadingEmployeeData] = useState({});
  const [pollsData, setPollsData] = useState([]);

  const [employeePrivateData, setEmployeePrivateData] = useState(null);
  const [loadingEmployeePrivateData, setLoadingEmployeePrivateData] =
    useState(true);

  const [employeeAdminData, setEmployeeAdminData] = useState(null);
  const [loadingEmployeeAdminData, setLoadingEmployeeAdminData] =
    useState(true);

  const [visitsData, setVisitsData] = useState({});

  const [allOpenVisitsData, setAllOpenVisitsData] = useState({});

  const [openVisitsData, setOpenVisitsData] = useState({});
  const [visitsNeedActionData, setVisitsNeedActionData] = useState({});

  const [eventsData, setEventsData] = useState({});

  const [loading, setLoading] = useState(true); // Initial loading state
  const [currentDayWithVisits, setCurrentDayWithVisits] = useState();

  const [inStandaloneMode, setInStandaloneMode] = useState(() => {
    return (
      window.navigator.standalone ||
      window.matchMedia("(display-mode: standalone)").matches
    );
  });

  // Polling data with onSnapshot
  useEffect(() => {
    if (!currentUser?.uid) {
      setPollsData([]);
      return;
    }

    const now = Timestamp.now();
    const pollsRef = collection(db, "polls");
    const q = query(pollsRef, where("endDate", ">", now));

    // Real-time listener
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const allPolls = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPollsData(allPolls);
    });

    // Cleanup on unmount
    return () => unsubscribe();
  }, [currentUser?.uid]);

  useEffect(() => {
    if (
      !currentUser ||
      employeePrivateData?.appInstalled ||
      !inStandaloneMode
    ) {
      console.log("Effect skipped");
      return;
    }

    console.log("inStandaloneMode: ", inStandaloneMode);

    async function updateInstallationStatus() {
      const employeesPrivateDocRef = doc(
        db,
        "employeesPrivate",
        currentUser.uid
      );

      try {
        await updateDoc(employeesPrivateDocRef, {
          appInstalled: true,
        });
      } catch (error) {
        console.error("Failed to update user documents:", error);
      }
    }

    updateInstallationStatus();
  }, [currentUser, inStandaloneMode]);

  useEffect(() => {
    if (!currentUser?.uid) return;

    setLoadingEmployeeData(true);

    const unsubEmployeeData = onSnapshot(
      doc(db, "employees", currentUser?.uid),
      (doc) => {
        setEmployeeData({ id: doc.id, ...doc.data() });
        setLoadingEmployeeData(false); // Set loading to false after data is fetched
      },
      (error) => {
        console.error("Error fetching employee data:", error);
        setLoadingEmployeeData(false); // Set loading to false even if there's an error
      }
    );

    // Cleanup function
    return () => {
      unsubEmployeeData();
      setLoadingEmployeeData(false); // Reset loading on unmount
    };
  }, [currentUser?.uid]);

  // Login streak
  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        if (currentUser?.uid) {
          // Reference to the employee document
          const employeePrivateDocRef = doc(
            db,
            "employeesPrivate",
            currentUser?.uid
          );

          // Fetch the employee document from Firestore
          const employeePrivateDoc = await getDoc(employeePrivateDocRef);

          const employeePrivateData = employeePrivateDoc.data();

          // Call handleCheckInStreak after fetching employee data
          handleCheckInStreak(
            employeePrivateData?.progress?.streaks?.appCheckIn?.lastWeekDate,
            currentUser?.uid
          );

          // Determine browser timezone
          const timeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;

          // Update lastActive to now
          await updateDoc(doc(db, "employees", currentUser?.uid), {
            "admin.lastActive": Timestamp.now(),
            timeZoneId,
          });
        }
      } catch (error) {
        console.error("Error fetching employee data: ", error);
      }
    };

    fetchEmployeeData(); // Trigger the fetch on component mount
  }, [currentUser?.uid]);

  const handleCheckInStreak = async (lastWeekDate, employeeId) => {
    const now = DateTime.now().startOf("day"); // Get today's date, normalized

    // If lastWeekDate is undefined, initialize the streak
    if (!lastWeekDate) {
      await updateDoc(doc(db, "employeesPrivate", employeeId), {
        "progress.streaks.appCheckIn.count": 1,
        "progress.streaks.appCheckIn.lastWeekDate": Timestamp.now(),
      });
      return;
    }

    const lastWeekDateTime = DateTime.fromJSDate(lastWeekDate.toDate()).startOf(
      "day"
    ); // Convert to Luxon DateTime and normalize
    const daysSinceLastWeek = now.diff(lastWeekDateTime, "days").days;

    if (daysSinceLastWeek >= 7 && daysSinceLastWeek < 14) {
      // Increment the streak if last active was between 7 and 14 days ago
      await updateDoc(doc(db, "employeesPrivate", employeeId), {
        "progress.streaks.appCheckIn.count": increment(1),
        "progress.streaks.appCheckIn.lastWeekDate": Timestamp.now(),
      });
    } else if (daysSinceLastWeek >= 14) {
      // Reset the streak if last active was more than 14 days ago
      await updateDoc(doc(db, "employeesPrivate", employeeId), {
        "progress.streaks.appCheckIn.count": 1,
        "progress.streaks.appCheckIn.lastWeekDate": Timestamp.now(),
      });
    }
  };

  // Subscription to usersPrivate collection
  useEffect(() => {
    if (!currentUser?.uid) return;

    setLoadingEmployeePrivateData(true);

    const unsubEmployeePrivateData = onSnapshot(
      doc(db, "employeesPrivate", currentUser?.uid),
      (doc) => {
        setEmployeePrivateData({ id: doc.id, ...doc.data() });
        setLoadingEmployeePrivateData(false); // Set loading to false after data is fetched
      },
      (error) => {
        console.error("Error fetching employee data:", error);
        setLoadingEmployeePrivateData(false); // Set loading to false even if there's an error
      }
    );
    
    setLoadingEmployeeAdminData(true);

    const unsubEmployeeAdminData = onSnapshot(
      doc(db, "employeesAdmin", currentUser?.uid),
      (doc) => {
        setEmployeeAdminData({ id: doc.id, ...doc.data() });
        setLoadingEmployeeAdminData(false); // Set loading to false after data is fetched
      },
      (error) => {
        console.error("Error fetching employee data:", error);
        setLoadingEmployeeAdminData(false); // Set loading to false even if there's an error
      }
    );

    // Cleanup function
    return () => {
      unsubEmployeePrivateData();
      unsubEmployeeAdminData();
      setLoadingEmployeePrivateData(false); // Reset loading on unmount
      setLoadingEmployeeAdminData(false); // Reset loading on unmount
    };
  }, [currentUser?.uid]);

  // Queried VISITS for CURRENT USER
  useEffect(() => {
    // Get the user's UID. Here it's assumed you have access to this information, perhaps through context, props, or a global state.
    const userUID = currentUser?.uid;

    if (!userUID) return;

    console.log("userUID:", userUID);

    // Define a reference to the 'visits' collection.
    const visitsRef = collection(db, "visits");

    const visitsQuery = query(
      visitsRef,
      where("employeeArr", "array-contains", userUID), // Checks if the user's UID is in the 'employeeArr' array
      where("status", "==", "confirmed") // Filter visits where status is "confirmed"
    );

    // Attach a listener for this query.
    const unsubscribe = onSnapshot(visitsQuery, (querySnapshot) => {
      const visits = {};
      querySnapshot.forEach((doc) => {
        // Store the visit data in the 'visits' object using the visit ID as the key.
        visits[doc.id] = doc.data();
      });

      // Update the state with the new visits data.
      setVisitsData(visits);
    });

    // Cleanup function
    return () => {
      unsubscribe();
    };
  }, [currentUser?.uid]);

  useEffect(() => {
    if (employeeData.status === "onboarding") {
      const timeZoneId = employeeData?.timeZoneId || "America/Los_Angeles";

      // Use Luxon to calculate start and end times
      const dayStart = DateTime.local()
        .setZone(timeZoneId)
        .set({ hour: 8, minute: 0, second: 0, millisecond: 0 });
      const startA = dayStart.startOf("hour"); // Next hour
      const endA = startA.plus({ hours: 2 }); // 2 hours later

      const startB = dayStart.plus({ days: 1, hours: 2.5 }); // Next hour
      const endB = startB.plus({ days: 1, hours: 2 }); // 2 hours later

      const startC = dayStart.plus({ days: 2, hours: 1 }).startOf("hour"); // Next hour
      const endC = startC.plus({ hours: 2 }); // 2 hours later

      const testVisitA = {
        start: Timestamp.fromDate(startA.toJSDate()), // Start time
        end: Timestamp.fromDate(endA.toJSDate()), // End time
        frequency: 0, // Single occurrence
        employees: {
          [employeeData.id]: {
            avatarUrl: employeeData.avatarUrl || "",
            displayName: `${
              employeeData.firstName
            } ${employeeData.lastName.charAt(0)}.`,
            comments: "",
            rating: null,
            responseStatus: "accepted",
            rate: null,
            bonus: null,
          },
        },
        location: {
          city: "Anacortes",
          state: "Washington",
          zipCode: "98221",
        },
        description:
          "Please help me move some boxes out of my garage, and then weed the small flowerbed out front. Thanks!",
        private: {
          address: {
            line1: "2016 C Ave",
            line2: "",
            directions:
              "Park at the base of the long driveway with the blue truck out front.",
            city: "Anacortes",
            state: "Washington",
            zipCode: "98221",
          },
          phone: "5555555555",
          title: "Jane Doe",
        },
        avatarUrl: "",
        displayName: "Jane D.",
        account: "test-account", // Placeholder
        id: "test-visit-A", // Unique visit ID
        status: "test",
        timeZoneId,
      };

      const testVisitB = {
        start: Timestamp.fromDate(startB.toJSDate()), // Start time
        end: Timestamp.fromDate(endB.toJSDate()), // End time
        frequency: 0, // Single occurrence
        employees: {
          [employeeData.id]: {
            avatarUrl: employeeData.avatarUrl || "",
            displayName: `${
              employeeData.firstName
            } ${employeeData.lastName.charAt(0)}.`,
            comments: "",
            rating: null,
            responseStatus: "needsAction",
            rate: null,
            bonus: null,
          },
        },
        location: {
          city: "Anacortes",
          state: "Washington",
          zipCode: "98221",
        },
        description:
          "Please help me move some boxes out of my garage, and then weed the small flowerbed out front. Thanks!",
        private: {
          address: {
            line1: "2016 C Ave",
            line2: "",
            directions:
              "Park at the base of the long driveway with the blue truck out front.",
            city: "Anacortes",
            state: "Washington",
            zipCode: "98221",
          },
          phone: "5555555555",
          title: "Mary Smith",
        },
        avatarUrl: "",
        isOpenVisit: true,
        displayName: "Mary S.",
        account: "test-account", // Placeholder
        id: "test-visit-B", // Unique visit ID
        status: "test",
        timeZoneId,
      };

      const testVisitC = {
        start: Timestamp.fromDate(startC.toJSDate()), // Start time
        end: Timestamp.fromDate(endC.toJSDate()), // End time
        frequency: 0, // Single occurrence
        employees: {},
        location: {
          city: "Anacortes",
          state: "Washington",
          zipCode: "98221",
        },
        description:
          "Please help me move some boxes out of my garage, and then weed the small flowerbed out front. Thanks!",
        private: {
          address: {
            line1: "2016 C Ave",
            line2: "",
            directions:
              "Park at the base of the long driveway with the blue truck out front.",
            city: "Anacortes",
            state: "Washington",
            zipCode: "98221",
          },
          phone: "5555555555",
          title: "Alice Brown",
        },
        isOpenVisit: true,
        employeesNeeded: 1,
        avatarUrl: "",
        displayName: "Alice B.",
        account: "test-account", // Placeholder
        id: "test-visit-C", // Unique visit ID
        status: "test",
        timeZoneId,
      };

      // Assuming visitsData is a state or context object
      setVisitsData((prev) => ({
        ...prev,
        ["test-visit-A"]: testVisitA, // Add the test visit with its ID as the key
        ["test-visit-B"]: testVisitB, // Add the test visit with its ID as the key
      }));

      // Assuming visitsData is a state or context object
      setOpenVisitsData((prev) => ({
        ...prev,
        ["test-visit-C"]: testVisitC, // Add the test visit with its ID as the key
      }));
    }
  }, [employeeData]);

  console.log("EmployeeProvider - openVisitsData: ", openVisitsData);

  // Calculate counts of visits that need action
  useEffect(() => {
    if (!visitsData || Object.keys(visitsData).length === 0 || !employeeData)
      return;

    const timeZoneId = employeeData?.timeZoneId;

    const visitsNeedActionMap = {};

    const now = moment().tz(timeZoneId);

    Object.entries(visitsData).forEach(([key, visit]) => {
      const visitStartMoment = moment(visit.start.toDate()).tz(
        visit.timeZoneId
      );

      const isFutureVisit = visitStartMoment.isAfter(now);

      const needsAction =
        visit.employees[currentUser?.uid]?.responseStatus === "needsAction" &&
        isFutureVisit;

      if (needsAction) {
        visitsNeedActionMap[key] = visit;
      }
    });

    setVisitsNeedActionData(visitsNeedActionMap);
  }, [visitsData, employeeData?.timeZoneId, currentUser?.uid]);

  useEffect(() => {
    // Check if employee data is loaded
    if (!employeeData) {
      return;
    }

    const areas = employeeData?.areas;
    const availability = employeeData?.availability;

    // Further checks
    if (!areas || employeeData?.status === "applicant") {
      return;
    }

    // We are using a Map here to benefit from its methods and to avoid duplicate visits
    const allVisits = new Map();

    // Store all unsubscribe functions
    const unsubscribeFunctions = [];

    // Iterate over each area in the areas map
    const bounds = groupGeohashBounds(employeeData?.geohash5Arr, 4);

    console.log("EmployeeProvider OpenVisits - bounds: ", bounds);

    bounds.forEach(([start, end]) => {
      const q = query(
        collection(db, "visits"),
        where("isOpenVisit", "==", true),
        where("status", "==", "confirmed"),
        orderBy("location.geohash"),
        startAt(start),
        endBefore(end + "\uf8ff") // Includes everything starting with 'end'
      );

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const changes = querySnapshot.docChanges();
        changes.forEach((change) => {
          if (change.type === "added" || change.type === "modified") {
            const visitData = change.doc.data();

            console.log("EmployeeProvider OpenVisits - visitData: ", visitData);

            const visitCoords = decodeGeoHash(visitData?.location?.geohash);

            const {
              inArea = false,
              futureAvailability,
              end,
              start,
            } = getEmployeeStatus(
              visitCoords[0],
              visitCoords[1],
              areas,
              availability
            );

            console.log("going through open visits: ", [inArea, end, start])
            console.log("For visit: ", visitData);

            if (
              inArea &&
              start <= DateTime.fromJSDate(visitData?.start.toDate()) &&
              (end === null ||
                end > DateTime.fromJSDate(visitData?.end.toDate()))
            ) {
              allVisits.set(change.doc.id, visitData); // Accumulating visits from all bounds
              console.log("Adding visit");
            }
          } else if (change.type === "removed") {
            console.log("Removing open visit: ", change.doc.id);
            allVisits.delete(change.doc.id);
          }
        });

        setAllOpenVisitsData(Object.fromEntries(allVisits));
        console.log("allOpenVisitsData: ", Object.fromEntries(allVisits));
      });

      // Add the unsubscribe function to the list
      unsubscribeFunctions.push(unsubscribe);
    });

    // Cleanup function
    return () => {
      unsubscribeFunctions.forEach((unsub) => unsub());
    };
  }, [employeeData?.areas, employeeData?.status]); // Dependency array

  // Get non-conflicting open visits, including 30-minute buffer and accounting for visit employee windows
  useEffect(() => {
    if (!allOpenVisitsData || !visitsData || !employeeData) return;

    if (employeeData.status === "onboarding") return;

    const timeZoneId = employeeData?.timeZoneId;

    // Convert the scheduled visits to an array of start and end moments with 30-minute padding
    const scheduledVisits = Object.values(visitsData).map((visit) => {
      // Check if employee-specific custom windows are defined
      const employeeWindows = visit.employees?.[currentUser?.uid]?.windows;

      if (employeeWindows) {
        // If custom windows exist, use those windows instead of visit.start and visit.end
        return Object.values(employeeWindows).map((window) => ({
          start: moment(window.start.toDate())
            .tz(visit?.timeZoneId)
            .subtract(30, "minutes"),
          end: moment(window.end.toDate())
            .tz(visit?.timeZoneId)
            .add(30, "minutes"),
        }));
      } else {
        // Default to using visit.start and visit.end if no custom windows
        return {
          start: moment(visit.start.toDate())
            .tz(visit?.timeZoneId)
            .subtract(30, "minutes"),
          end: moment(visit.end.toDate())
            .tz(visit?.timeZoneId)
            .add(30, "minutes"),
        };
      }
    });

    // Flatten the array if employeeWindows is used (it may return an array of arrays)
    const flatScheduledVisits = scheduledVisits.flat();

    // Get the current time with the specified timezone
    const now = moment().tz(timeZoneId);

    // Filter out open visits that overlap with any scheduled visits (including 30-minute padding)
    const nonOverlappingOpenVisits = Object.entries(allOpenVisitsData).reduce(
      (acc, [key, openVisit]) => {
        const openStart = moment(openVisit.start.toDate()).tz(timeZoneId);
        const openEnd = moment(openVisit.end.toDate()).tz(timeZoneId);

        const isNonOverlapping = !flatScheduledVisits.some(
          (scheduledVisit) =>
            openStart.isBefore(scheduledVisit.end) &&
            openEnd.isAfter(scheduledVisit.start)
        );

        // Check if the visit has not been hidden by the current employee
        const isNotHidden = !(openVisit.hideEmployeeArr || []).includes(
          currentUser?.uid
        );

        console.log("isNotHidden: ", isNotHidden);
        console.log("isNonOverlapping: ", isNonOverlapping);

        // Add additional check to ensure the open visit is in the future
        if (isNonOverlapping && isNotHidden && openStart.isAfter(now)) {
          acc[key] = openVisit; // Add to accumulator if it doesn't overlap, is not hidden, and is in the future
        }

        return acc;
      },
      {}
    );

    setOpenVisitsData(nonOverlappingOpenVisits);
  }, [
    allOpenVisitsData,
    visitsData,
    employeeData?.timeZoneId,
    currentUser?.uid,
  ]);

  useEffect(() => {
    if (!employeeData?.timeZoneId || (!visitsData && !openVisitsData)) return;

    const timeZoneId = employeeData.timeZoneId;
    const now = moment().tz(timeZoneId); // Get the current time with the correct timezone
    const startOfToday = now.clone().startOf("day"); // Clone now before using startOf("day")

    // Find the earliest visit starting from today
    const futureVisits = Object.values(visitsData || {})
      .map((visit) => moment(visit.start.toDate()).tz(timeZoneId))
      .filter((visitStart) => visitStart.isSameOrAfter(startOfToday));

    // Sort and get the earliest future visit
    const earliestFutureVisit = futureVisits.sort((a, b) => a.diff(b))[0];

    if (earliestFutureVisit) {
      // If a future visit is found, use that day
      setCurrentDayWithVisits(earliestFutureVisit.startOf("day"));
    } else if (openVisitsData) {
      // If no future visits, try to find the earliest open visit
      const futureOpenVisits = Object.values(openVisitsData || {})
        .map((openVisit) => moment(openVisit.start.toDate()).tz(timeZoneId))
        .filter((openVisitStart) => openVisitStart.isAfter(now)); // Ensure open visit is after the current time

      // Sort and get the earliest open visit
      const earliestOpenVisit = futureOpenVisits.sort((a, b) => a.diff(b))[0];

      if (earliestOpenVisit) {
        setCurrentDayWithVisits(earliestOpenVisit.startOf("day"));
      } else {
        setCurrentDayWithVisits(null); // No future visits or open visits found
      }
    } else {
      // No future visits and no open visits, set to null
      setCurrentDayWithVisits(null);
    }
  }, [visitsData, openVisitsData, employeeData?.timeZoneId]);

  // // POLYGON EVENTS nearby - Use effect to find nearby events
  // useEffect(() => {
  //   const employeeLocationHash = employeeData?.location?.geohash;
  //   if (!employeeLocationHash) return;

  //   const employeeGeohashCoordinates = decodeGeoHash(employeeLocationHash);
  //   console.log("employeeGeohashCoordinates: ", employeeGeohashCoordinates);
  //   const radiusInM = 10 * 1609.34; // 10 miles in meters, adjust as needed

  //   const bounds = geohashQueryBounds(employeeGeohashCoordinates, radiusInM);

  //   const matchedAddress = employeePrivateData?.address[0];

  //   if (!matchedAddress) return;

  //   const employeeCoordinates = {
  //     lat: matchedAddress.lat,
  //     lng: matchedAddress.lng,
  //   };
  //   console.log("employeeCoordinates: ", employeeCoordinates);

  //   const eventsRef = collection(db, "events"); // Adjust according to your events collection name
  //   const eventUnsubscribes = [];

  //   bounds.forEach(([start, end]) => {
  //     const eventsQuery = query(
  //       eventsRef,
  //       where("status", "==", "active"), // Filter events where status is "active"
  //       orderBy("location.geohash"),
  //       startAt(start),
  //       endAt(end)
  //     );

  //     const unsubscribe = onSnapshot(eventsQuery, (querySnapshot) => {
  //       const newEvents = {};
  //       querySnapshot.forEach((doc) => {
  //         const eventData = doc.data();

  //         console.log("employeeCoordinates: ", employeeCoordinates);
  //         console.log("eventData.location.bounds: ", eventData.location.bounds);

  //         // TODO: Fix this with employeeCoordinates
  //         // Assuming eventData.location.bounds is an array of { lat, lng } points defining the polygon
  //         if (
  //           isPointInPolygon(employeeCoordinates, eventData.location.bounds)
  //         ) {
  //           console.log("is within bounds");
  //           newEvents[doc.id] = eventData;
  //         }
  //       });

  //       // Update the state with new events within maxDistance
  //       setEventsData(...prevData.events, ...newEvents);
  //     });

  //     eventUnsubscribes.push(unsubscribe);
  //   });

  //   // Cleanup function
  //   return () => {
  //     eventUnsubscribes.forEach((unsub) => unsub());
  //   };
  // }, [
  //   employeeData?.location?.geohash,
  //   employeePrivateData?.address?.[0] ?? null,
  // ]); // Dependency array

  // Conditionally render the LoadingUser component if loading
  if (loadingEmployeeData || loadingEmployeePrivateData) {
    return (
      <LoadingUser
        loading={loadingEmployeeData || loadingEmployeePrivateData}
      />
    );
  }

  return (
    <EmployeeContext.Provider
      value={{
        employeeData,
        employeePrivateData,
        employeeAdminData,
        visitsData,
        setVisitsData,
        openVisitsData,
        setOpenVisitsData,
        visitsNeedActionData,
        currentDayWithVisits,
        loadingEmployeeData,
        loadingEmployeePrivateData,
        pollsData,
      }}
    >
      {children}
    </EmployeeContext.Provider>
  );
};

export default EmployeeProvider;

export const useEmployee = () => {
  return useContext(EmployeeContext);
};
