import { useEffect } from "react";
import { useSession } from "../providers/SessionProvider";

const useLogPageVisit = (path) => {
  const { logPageVisit } = useSession();

  useEffect(() => {
    logPageVisit(path);
  }, [logPageVisit, path]); // Dependency array ensures this only runs when path changes
};

export default useLogPageVisit;
