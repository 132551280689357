import { Phone } from "@mui/icons-material";
import { Box } from "@mui/material";
import React from "react";

const LiveCallAnimation = () => {
  // Array of delays for the ripple animations
  const delays = [0];

  return (
    <Box
      sx={{
        position: "relative",
        width: "32px",
        height: "32px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* Ripple Disks */}
      {delays.map((delay, index) => (
        <Box
          key={index}
          sx={{
            position: "absolute",
            width: "32px",
            height: "32px",
            borderRadius: "50%",
            backgroundColor: "white",
            animation: `ripple 3s ease-in-out infinite`,

          }}
        />
      ))}

      {/* Icon */}
      <Box
          sx={{
            position: "absolute",
            width: "32px",
            height: "32px",
            borderRadius: "50%",
            animation: `ripple-inverse 1.5s ease-in-out alternate infinite`,
            backgroundColor: "white",
          }}
        >
      </Box>
      <Phone
        sx={{
          color: "primary.main",
          zIndex: 1,
        }}
      />

      {/* Keyframes for animation */}
      <style>
        {`
          @keyframes ripple {
            0% {
              transform: scale(0.9);
              opacity: 0.8;
            }
            100% {
              transform: scale(1.5);
              opacity: 0;
            }
          }
          @keyframes ripple-inverse {
            0% {
              transform: scale(0.9);
            }
            100% {
              transform: scale(1);
            }

          }
        `}
      </style>
    </Box>
  );
};

export default LiveCallAnimation