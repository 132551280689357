import { Backspace, Cancel, Phone as PhoneIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

const DialPad = ({ inputNumber = "", onCall, onClose }) => {
  const [number, setNumber] = useState(inputNumber);

  const handleKeyPress = (digit) => {
    setNumber((prev) => prev + digit);
  };

  const handleBackspace = () => {
    setNumber((prev) => prev.slice(0, -1));
  };

  const handleClear = () => {
    setNumber("");
  };

  const handleCall = () => {
    if (onCall) {
      onCall(number);
      onClose();
    }
  };

  return (
    <>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        {/* Input Field */}
        <TextField
          variant="outlined"
          fullWidth
          value={number}
          onChange={(e) => setNumber(e.target.value.replace(/\D/g, ""))}
          placeholder="Enter a number"
          InputProps={{
            endAdornment: (
              <IconButton onClick={handleClear}>
                <Cancel />
              </IconButton>
            ),
          }}
        />

        {/* Call Button */}
      </DialogContent>
      <DialogActions
        sx={{
          gap: 1,
          flexDirection: "column",
        }}
      >
        {/* Keypad */}
        <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap={1} sx={{ width: "100%" }}>
  {[
    { digit: "1", letters: "\u00A0" },
    { digit: "2", letters: "A B C" },
    { digit: "3", letters: "D E F" },
    { digit: "4", letters: "G H I" },
    { digit: "5", letters: "J K L" },
    { digit: "6", letters: "M N O" },
    { digit: "7", letters: "P Q R S" },
    { digit: "8", letters: "T U V" },
    { digit: "9", letters: "W X Y Z" },
    { digit: "*", letters: "\u00A0" },
    { digit: "0", letters: "+" },
    { digit: "#", letters: "\u00A0" },
  ].map(({ digit, letters }) => (
    <Button
      key={digit}
      variant="outlined"
      onClick={() => handleKeyPress(digit)}
      sx={{
        fontSize: "1.5rem",
        width: "100%",
        height: "60px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant="body1" sx={{ fontSize: "1.5rem", fontWeight: "500", mt: "2px" }}>{digit}</Typography> 
      <Typography variant="caption" sx={{ fontSize: "0.55rem", fontWeight: "550", mt: "-4px", height: "14px" }}>
        {letters}
      </Typography>
    </Button>
  ))}
</Box>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginLeft: "0px",
            gap: "8px",
            width: "100%"
            // ml: -2,
          }}
        >
          <Button
            variant="text"
            sx={{
              width: "100%",
              height: "60px",
              color: "text.secondary",
              visibility: "hidden",
            }}
            disabled={!number}
          >
            <Backspace />
          </Button>
          <Button
            variant="contained"
            disableElevation
            color="success"
            onClick={handleCall}
            sx={{ width: "100%", height: "60px" }}
            disabled={!number}
          >
            <PhoneIcon />
          </Button>
          <Button
            variant="text"
            onClick={handleBackspace}
            sx={{ width: "100%", height: "60px", color: "text.secondary" }}
            disabled={!number}
          >
            <Backspace />
          </Button>
        </div>
      </DialogActions>
    </>
  );
};

export default DialPad;
