// src/contexts/AuthContext.js
import { onAuthStateChanged } from "firebase/auth";
import React, { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../firebase";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [customClaims, setCustomClaims] = useState(null); // State for custom claims
  const [loading, setLoading] = useState(true);

  const storeReferralInLocalStorage = () => {
    const query = new URLSearchParams(window.location.search);
    const referralParam = query.get("ref");

    if (referralParam) {
      localStorage.setItem("referrerId", referralParam);
    } else {
    }
  };

  useEffect(() => {
    storeReferralInLocalStorage();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);

      if (user) {
        // Fetch ID token and decode custom claims
        const idToken = await user.getIdTokenResult();
        setCustomClaims(idToken.claims); // Extract custom claims from the token
      } else {
        setCustomClaims(null); // Reset custom claims if the user is not logged in
      }

      setLoading(false);
    });

    return unsubscribe;
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, customClaims }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
