// updated Jan 1, 2025
export const MEMBER_COUNT = 529; 
export const VISIT_COUNT = 1210;
export const TASK_COUNT = 4414;

export const SENTIMENT_SCALES = {
  agreement: [
    "Strongly Disagree",
    "Disagree",
    "Neutral",
    "Agree",
    "Strongly Agree",
  ],
  emotion: [
    "Very Negative",
    "Negative",
    "Neutral",
    "Positive",
    "Very Positive",
  ],
  likelihood: [
    "Very Unlikely",
    "Unlikely",
    "Neutral",
    "Likely",
    "Very Likely",
  ],
  usability: ["Terrible", "Poor", "Average", "Good", "Excellent"],
  importance: [
    "Not Important",
    "Low Importance",
    "Neutral",
    "Important",
    "Very Important",
  ],
};