import { Device } from "@twilio/voice-sdk";
import { httpsCallable } from "firebase/functions";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { functions } from "../firebase";
import { useAuth } from "./AuthProvider";

const TwilioContext = createContext(null);

const MAX_RETRIES = 3; // Maximum number of retries for initialization
const RETRY_DELAY_MS = 5000; // Delay between retries in milliseconds
const TOKEN_REFRESH_INTERVAL_MS = 50 * 60 * 1000; // Refresh token every 50 minutes

const fetchTwilioToken = async () => {
  const getToken = httpsCallable(functions, "getTwilioAccessToken");
  const result = await getToken();
  return result.data.token;
};

export function useTwilio() {
  return useContext(TwilioContext);
}

export function TwilioProvider({ children }) {
  const { customClaims } = useAuth();

  const [device, setDevice] = useState(null);
  const [incomingCall, setIncomingCall] = useState(null);
  const [callInProgress, setCallInProgress] = useState(false);
  const [callSid, setCallSid] = useState(null);
  const [callStart, setCallStart] = useState(null);
  const [callerInfo, setCallerInfo] = useState("");

  const deviceRef = useRef(null);

  const refreshToken = async () => {
    try {
      const newToken = await fetchTwilioToken();
      if (deviceRef.current) {
        deviceRef.current.updateToken(newToken);
        console.log("Token refreshed successfully.");
      }
    } catch (error) {
      console.error("Failed to refresh Twilio token:", error);
    }
  };

  const initializeDevice = async (retryCount = 0) => {
    try {
      const token = await fetchTwilioToken();

      // Initialize the Device
      const newDevice = new Device(token, { logLevel: "debug" });

      // Wait for device to be ready before registering
      newDevice.on("ready", () => {
        console.log("Twilio Device is ready");
      });

      // Handle incoming calls
      newDevice.on("incoming", handleIncomingCall);

      newDevice.on("error", (error) => {
        console.error("Twilio Device error:", error);
      });

      deviceRef.current = newDevice;
      setDevice(newDevice);

      await newDevice.register();
      console.log("Device registered and ready for outgoing calls");
    } catch (error) {
      console.error(
        `Failed to initialize Twilio Device (Attempt ${retryCount + 1}):`,
        error
      );

      if (retryCount < MAX_RETRIES) {
        console.log(`Retrying in ${RETRY_DELAY_MS / 1000} seconds...`);
        setTimeout(() => initializeDevice(retryCount + 1), RETRY_DELAY_MS);
      } else {
        console.error(
          "Max retries reached. Failed to initialize Twilio Device."
        );
      }
    }
  };

  // Set refresh timer whenever new device is fetched.
  useEffect(() => {
    let refreshTimer;
    if (device) {
      refreshTimer = setTimeout(refreshToken, TOKEN_REFRESH_INTERVAL_MS);
    }
    return () => {
      if (refreshTimer) clearTimeout(refreshTimer);
    };
  }, [device]);

  useEffect(() => {
    const isAdmin = customClaims?.isAdmin || false;
    if (isAdmin) {
      initializeDevice();
    }

    return () => {
      if (deviceRef.current) {
        deviceRef.current.destroy();
        deviceRef.current = null;
        setDevice(null);
      }
    };
  }, [customClaims]);

  const handleIncomingCall = (call) => {
    setIncomingCall(call);
    setCallerInfo(call.parameters.From || "Unknown Caller");

    call.on("accept", () => {
      console.log("Incoming call accepted");
      setCallInProgress(true);
      setCallStart(Date.now());
      setCallSid(call.parameters.CallSid || null);
    });

    call.on("disconnect", () => {
      console.log("Incoming call ended");
      resetCallState();
    });

    call.on("error", (error) => {
      console.error("Error during incoming call:", error);
    });
  };

  const makeOutgoingCall = async (toNumber) => {
    if (!deviceRef.current) {
      console.error("Twilio Device is not initialized");
      return;
    }

    try {
      // Await the promise to get the Call object
      const call = await deviceRef.current.connect({
        params: { To: toNumber },
      });

      call.on("accept", () => {
        console.log("Outgoing call connected");
        setCallInProgress(true);
        setCallStart(Date.now());
        setCallSid(call.parameters.CallSid || null);
      });

      call.on("disconnect", () => {
        console.log("Outgoing call ended");
        resetCallState();
      });

      call.on("error", (error) => {
        console.error("Error during outgoing call:", error);
      });
    } catch (error) {
      console.error("Failed to initiate outgoing call:", error);
    }
  };

  const resetCallState = () => {
    setIncomingCall(null);
    setCallInProgress(false);
    setCallSid(null);
    setCallStart(null);
    setCallerInfo("");
  };

  const answerCall = () => {
    if (incomingCall) {
      incomingCall.accept();
    }
  };

  const declineCall = () => {
    if (incomingCall) {
      incomingCall.reject();
      resetCallState();
    }
  };

  return (
    <TwilioContext.Provider
      value={{
        device,
        incomingCall,
        callerInfo,
        callInProgress,
        answerCall,
        declineCall,
        makeOutgoingCall,
      }}
    >
      {children}
    </TwilioContext.Provider>
  );
}
