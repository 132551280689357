import {
  AccessTime,
  EventAvailable,
  Face,
  FavoriteBorder,
  PaidOutlined,
  Star,
  StarOutline,
  WorkOutline,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  Grid,
  Rating,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import InfiniteScrollAvatars from "../../../components/popup/InfiniteScrollAvatars";
import StyledChip from "../../../components/styled/StyledChip";

function HiringLanding() {
  const theme = useTheme(); // hook to get current theme
  const navigate = useNavigate();

  // Handle button click to scroll and update URL
  const handleScroll = (sectionId) => {
    // Prevent default action if this is triggered by an event
    // event.preventDefault(); // Uncomment if handleScroll is called with an event argument

    const element = document.getElementById(sectionId);

    if (element) {
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }

    // Update the URL hash
    window.history.pushState({}, "", `#${sectionId}`);
  };

  const perks = [
    { icon: <AccessTime />, label: "2-10 hours per week" },
    { icon: <EventAvailable />, label: "Set your schedule" },
    { icon: <WorkOutline />, label: "Works with existing job" },
    { icon: <Face />, label: "Great for young adults" },
    { icon: <FavoriteBorder />, label: "Feel appreciated" },
    { icon: <PaidOutlined />, label: "Meaningful side hustle" },
  ];

  return (
    <Grid container spacing={0} sx={{ display: { xs: "block", md: "flex" } }}>
      {/* Left Box with Image */}
      {/* <Grid item xs={12} md={6}>
        <Box
          sx={{
            height: { xs: "250px", md: "100%" }, // 150px tall on mobile and 50% viewport height on desktop and up
            backgroundImage: `url(${Image1})`,
            backgroundSize: "cover",
            backgroundPosition: "15% 50%",
          }}
        ></Box>
      </Grid> */}
      <InfiniteScrollAvatars />

      {/* Right Box with Centered Content */}
      {/* <Grid item xs={12} md={6}> */}
      <Box
        sx={{
          backgroundColor: "white", //theme.palette.primary.lightest,
          maxWidth: "600px",
          mx: "auto",
        }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        // height={{ xs: "auto", md: "50vh" }} // auto height on mobile and 50% viewport height on desktop and up
        padding={{ xs: "32px 16px", md: "50px" }}
      >
        <Box>
        <StyledChip label="Across the U.S." color="primary" size="normal" />
          <Typography
            variant="h2"
            sx={{ fontSize: { xs: "52px", sm: "60px" }, mb: "8px" }}
          >
            We're Hiring

          </Typography>
          <Typography
            variant="h5"
            sx={{ fontWeight: "600", fontSize: { xs: "20px", sm: "24px" } }}
            textAlign="left"
            gutterBottom
          >
            Help seniors with basic household tasks.
          </Typography>
          <Typography variant="body1" paragraph>
            Looking for meaningful part-time work? We're excited for you to join
            our excellent team. You'll help older adults (age 55+) within your
            local area with household tasks, from gardening to organizing, tech
            help to cleaning, and more!
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 2 }}>
            {perks.map((item, index) => (
              <Chip
                key={index}
                icon={item.icon}
                label={item.label}
                // variant="outlined"
              />
            ))}
          </Box>
          <Button
            variant="contained"
            fullWidth
            size="large"
            // component={Link}
            // href="/helper/signup"
            // underline="none"
            onClick={() => navigate("/helper/signup")}
            sx={{ height: "56px", textTransform: "none" }}
          >
            Apply now
          </Button>
          {/* <Typography variant="body1" paragraph>
              Linked Lives helps seniors with odd jobs and tasks around their
              homes as we prioritize exceptional service and meaningful
              relationships.
            </Typography> */}
          <Box
            sx={{
              // bgcolor: "#f5f5f5",
              background:
                "linear-gradient(to right, hsl(150,100%,95%), hsl(191,100%,95%))",
              p: 2,
              // boxShadow:
              //   "0 8px 12px rgba(0, 0, 0, 0.1), 0 5px 20px hsla(150,100%,70%,0.2)", // Increase shadow on hover
              borderRadius: 2,
              textAlign: "center",
              mx: "auto",
              my: 2,
            }}
          >
            <Typography
              variant="h6"
              component="div"
              sx={{ fontWeight: "bold" }}
            >
              Voted 5 Stars
            </Typography>
            <Rating
              value={5}
              readOnly
              precision={0.5}
              sx={{
                fontSize: "3rem",
                color: "#FFD700",
              }}
              icon={<Star fontSize="56px" />}
              emptyIcon={<StarOutline fontSize="56px" />}
            />
            <Typography variant="body2" sx={{ mt: 1, color: "text.secondary" }}>
              by our helpers for <strong>Job Satisfaction</strong>,{" "}
              <strong>Leadership</strong>, and <strong>Company Culture</strong>
            </Typography>
          </Box>

          <Box align="center"></Box>
        </Box>
      </Box>
      {/* </Grid> */}
    </Grid>
  );
}

export default HiringLanding;
