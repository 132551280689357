export const formatName = (str) => {
  if (typeof str !== "string" || !str) {
    // Return the original input if it's not a string or is falsy (empty, null, undefined)
    return str;
  }

  return str
    .split(" ")
    .map((word) =>
      word ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : ""
    )
    .join(" ");
};

export const formatFirstName = (strArray) => {
  if (!Array.isArray(strArray) || strArray.length === 0) return "";

  // Format each name in the array
  const formattedNames = strArray.map((str) => {
    if (!str) return "";

    // Remove the last three characters if they match " space CapitalizedLetter period"
    if (/\s[A-Z]\.$/.test(str)) {
      str = str.slice(0, -3);
    }

    return str
      .toLowerCase()
      .replace(/\b\w+\b/g, (word) =>
        word === "and" ? "and" : word[0].toUpperCase() + word.slice(1)
      );
  });

  // Join names into a natural sentence
  return formattedNames.length <= 2
    ? formattedNames.join(" and ")
    : `${formattedNames.slice(0, -1).join(", ")}, and ${formattedNames.slice(-1)}`;
};


export const formatPhoneNumber = (phoneNumber) => {
  // Check if the input is a string and not null or undefined
  if (!phoneNumber || typeof phoneNumber !== "string") {
    return;
  }

  // Remove all non-digit characters
  const digits = phoneNumber.replace(/\D/g, "");

  // Format the phone number as (XXX) XXX-XXXX
  return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6)}`;
};
