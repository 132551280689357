import {
  collection,
  doc,
  endAt,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  startAt,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { distanceBetween, geohashQueryBounds } from "geofire-common"; // make sure you have 'geofire-common' installed
import moment from "moment-timezone";
import React, { createContext, useContext, useEffect, useState } from "react";
import LoadingUser from "../components/LoadingUser";
import { db } from "../firebase";
import {
  decodeGeoHash,
  getEmployeeStatus,
  isPointInPolygon,
} from "../services/locationServices";
import { useAuth } from "./AuthProvider";
import { useSession } from "./SessionProvider";

const MemberContext = createContext();

export const useMember = () => {
  return useContext(MemberContext);
};

export const MemberProvider = ({ children }) => {
  // const [data, setData] = useState(null);
  const { createLog } = useSession();

  const { currentUser } = useAuth(); // Use your auth provider to get the current user

  const [data, setData] = useState(() => ({
    auth: {},
    member: {},
    account: {},
    visits: {},
    // ... other data fields
  }));

  const [memberData, setMemberData] = useState(null);
  const [loadingMemberData, setLoadingMemberData] = useState(true);

  const [visitsData, setVisitsData] = useState({});
  const [loadingVisitsData, setLoadingVisitsData] = useState(true);

  const [memberPrivateData, setMemberPrivateData] = useState(null);
  const [loadingMemberPrivateData, setLoadingMemberPrivateData] =
    useState(true);

  const [memberAdminData, setMemberAdminData] = useState(null);
  const [loadingMemberAdminData, setLoadingMemberAdminData] = useState(true);

  const [memberAccountData, setMemberAccountData] = useState(null);
  const [loadingAccountData, setLoadingAccountData] = useState(true); // Initial loading state

  const [loadingEmployees, setLoadingEmployees] = useState(true);

  const [nextVisit, setNextVisit] = useState();
  const [prevVisit, setPrevVisit] = useState();

  /////// TRACKER

  const getDeviceInfo = () => {
    return {
      deviceType: navigator.userAgent,
      isStandalone: window.matchMedia("(display-mode: standalone)").matches,
      width: window.innerWidth,
      height: window.innerHeight,
      platform: navigator.userAgentData?.platform || "unknown",
      userAgent: navigator.userAgent,
    };
  };

  const logNewSession = async () => {
    try {
      // Update lastActive to now
      await updateDoc(doc(db, "membersPrivate", currentUser?.uid), {
        "admin.lastActive": Timestamp.now(),
      });
    } catch (error) {
      console.error("Error fetching employee data: ", error);
    }
  };

  // AUTH // MEMBER // MEMBER.PRIVATE
  useEffect(() => {
    if (currentUser?.uid) {
      try {
        // Member data listener
        const unsubMember = onSnapshot(
          doc(db, "members", currentUser?.uid),
          (docSnapshot) => {
            setMemberData({ id: docSnapshot.id, ...docSnapshot.data() });
            setLoadingMemberData(false);
          }
        );

        // Private data listener
        const unsubPrivate = onSnapshot(
          doc(db, "membersPrivate", currentUser?.uid),
          (doc) => {
            setMemberPrivateData({ id: doc.id, ...doc.data() });

            // Stop loading after private data is fetched
            setLoadingMemberPrivateData(false);
          },
          (error) => {
            console.error("Error fetching private data:", error);
            // Stop loading if an error occurs
            setLoadingMemberPrivateData(false);
          }
        );

        // Admin data listener
        const unsubAdmin = onSnapshot(
          doc(db, "members", currentUser?.uid, "admin", "data"),
          (doc) => {
            setMemberAdminData(doc.data());
            setLoadingMemberAdminData(false);
          }
        );

        return () => {
          unsubMember();
          unsubPrivate();
          unsubAdmin();
        };
      } catch (error) {
        console.error("Error setting up snapshot listener:", error);
      } finally {
        createLog({
          collectionName: "members",
          idsArr: [currentUser?.uid],
          summary: `Visited the dashboard.`,
          logType: "activity",
        });
        logNewSession();
      }
    }
  }, []);

  // ACCOUNT
  useEffect(() => {
    const accountId = memberAdminData?.accountId || currentUser?.uid; // if there's no accountId, the default is the memberId

    if (!accountId) return; // Return early if there is no account ID

    console.log("-------- init snapshot listener: account ----------");

    const accountDocRef = doc(db, "accounts", accountId);

    const unsubscribe = onSnapshot(accountDocRef, (accountDoc) => {
      if (accountDoc.exists()) {
        const accountData = accountDoc.data();

        setData((prevData) => ({
          ...prevData,
          account: accountData,
        }));
        setMemberAccountData(accountData);
      }
    });

    setLoadingAccountData(false);

    // When this useEffect cleans up, unsubscribe from the account listener
    return () => {
      console.log("!!!!!!!!!!! unsubscribed from account !!!!!!!!!!!");
      unsubscribe();
    };
  }, [
    currentUser?.uid,
    memberAdminData?.accountId,
    memberPrivateData?.defaultAddress,
  ]);

  // Queried VISITS
  useEffect(() => {
    // Get the user's UID. Here it's assumed you have access to this information, perhaps through context, props, or a global state.
    const accountId = memberAdminData?.accountId || currentUser?.uid; // if there's no accountId, the default is the memberId
    if (!accountId) return;

    console.log("accountId:", accountId);

    // Define a reference to the 'visits' collection.
    const visitsRef = collection(db, "visits");

    // Construct the query.
    const visitsQuery = query(
      visitsRef,
      where("account", "==", accountId) // Checks if the user's UID exists in the 'employees' field of the visit document.
      // where('start', '>', new Date()), // Assuming 'start' is a timestamp, this condition checks if it's later than now.
      // where("status", "==", "confirmed") // Filter visits where status is "confirmed".
    );

    // Attach a listener for this query.
    const unsubscribe = onSnapshot(visitsQuery, (querySnapshot) => {
      const visits = {};
      querySnapshot.forEach((doc) => {
        // Store the visit data in the 'visits' object using the visit ID as the key.
        visits[doc.id] = doc.data();
      });

      // Update visitsData state
      setVisitsData(visits);

      // Update loading state
      setLoadingVisitsData(false);
    });

    // Cleanup function
    return () => {
      unsubscribe();
    };
  }, [currentUser?.uid, memberAdminData?.accountId]);

  // Queried GEOHASH EMPLOYEES
  useEffect(() => {
    if (!memberPrivateData?.location?.geohash) {
      setLoadingEmployees(false);
      return;
    }

    const memberGeohash5 = memberPrivateData.location.geohash.slice(0, 5); // Use the member's precise geohash

    console.log("memberGeohash5: ", memberGeohash5);

    const memberLocation = memberPrivateData.location; // Use the member's precise geohash
    const memberCoordinates = {
      lat: memberLocation.lat,
      lng: memberLocation.lng,
    }; // { lat, lng } pair

    console.log("memberCoordinates: ", memberCoordinates);

    // Query Firestore using array-contains to match the geohash
    const q = query(
      collection(db, "employees"),
      where("status", "==", "active"),
      where("geohash5Arr", "array-contains", memberGeohash5) // Check if the geohash exists in geohash5Arr
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const employeesData = {};

      querySnapshot.forEach((doc) => {
        const employeeData = doc.data();

        // new way
        const {
          inArea = false,
          futureAvailability,
          end,
          start,
        } = getEmployeeStatus(
          memberLocation?.lat,
          memberLocation?.lng,
          employeeData.areas,
          employeeData.availability
        );
        if (
          inArea &&
          typeof start !== "undefined" &&
          typeof end !== "undefined"
        ) {
          employeesData[doc.id] = {
            ...employeeData,
            startDate: start,
            endDate: end,
          };
        }
      });

      console.log("employeesData: ", employeesData);

      // Update state with all aggregated employees
      setData((prevData) => ({
        ...prevData,
        employees: employeesData,
      }));

      setLoadingEmployees(false);
    });

    // Cleanup function to unsubscribe from listeners
    return () => {
      unsubscribe();
    };
  }, [memberPrivateData?.location?.geohash]); // Dependency array

  // Queried GEOHASH MEMBERS
  useEffect(() => {
    if (
      memberData?.status !== "waitlist" ||
      !memberPrivateData?.location?.geohash
    )
      return;

    console.log("No IDS to add, getting local peeps");

    const center = decodeGeoHash(memberPrivateData?.location?.geohash);
    const radiusInM = 5 * 1609.34; // for example, 20 miles to meters

    const bounds = geohashQueryBounds(center, radiusInM);

    const allMembers = new Map(); // to store members data

    const unsubscribe = bounds.map(([start, end]) => {
      const q = query(
        collection(db, "members"), // Adjust according to your collection name
        where("status", "in", ["active", "waitlist"]), // Query for 'active' or 'waitlist'
        orderBy("location.geohash"),
        startAt(start),
        endAt(end)
      );

      return onSnapshot(q, (querySnapshot) => {
        const changes = querySnapshot.docChanges();
        changes.forEach((change) => {
          if (change.type === "added" || change.type === "modified") {
            const memberData = change.doc.data();
            let memberCoordinates = decodeGeoHash(
              memberPrivateData?.location?.geohash
            );

            const memberDistance =
              distanceBetween(center, memberCoordinates) * 0.621371; // convert to miles

            // Here you can check any condition you want to filter your members
            // For example, if they are within a certain distance
            if (memberDistance <= 15) {
              // YOUR_CONDITION is the radius within which you want to include members
              // console.log("adding member: ", change.doc.data().firstName);

              allMembers.set(change.doc.id, memberData);
            }
          } else if (change.type === "removed") {
            // console.log("removed member: ", change.doc.data().firstName);

            allMembers.delete(change.doc.id);
          }
        });

        // Do something with the aggregated members
        const aggregatedMembers = Object.fromEntries(allMembers);
        // console.log("aggregatedMembers: ", aggregatedMembers);

        setData((prevData) => ({
          ...prevData,
          waitlist: aggregatedMembers,
        }));

        // You can also manage the state for when there are no members available
        if (allMembers.size === 0) {
          console.log("No local members found");
          // handle the scenario appropriately
        }
      });
    });

    // Cleanup function to unsubscribe when the component is unmounted or when the dependencies of this useEffect change
    return () => {
      unsubscribe.forEach((unsub) => unsub());
    };
  }, [memberPrivateData?.location?.geohash]); // Add dependencies here

  // Get all announcements
  useEffect(() => {
    const fetchAnnouncementDetails = async () => {
      try {
        // Extract announcement IDs from the loaded data
        const announcementIds = memberPrivateData.announcements; // updated

        // Fetch details for each announcement by ID
        const announcements = await Promise.all(
          announcementIds.map(async (id) => {
            const docRef = doc(db, "announcements", id);
            const docSnap = await getDoc(docRef);
            return docSnap.exists()
              ? { id: docSnap.id, ...docSnap.data() }
              : null;
          })
        );

        // Filter out null values if some documents were not found
        const validAnnouncements = announcements.filter(Boolean);

        // Update the context or global state with the fetched announcement details
        setData((prevData) => ({
          ...prevData,
          announcements: validAnnouncements,
        }));
      } catch (error) {
        console.error("Error fetching announcement details:", error);
      }
    };

    if (memberPrivateData?.announcements && !data?.announcements) {
      fetchAnnouncementDetails();
    }
  }, [memberPrivateData?.announcements]);

  // POLYGON EVENTS nearby - Use effect to find nearby events
  useEffect(() => {
    const memberLocationHash = memberPrivateData?.location?.geohash;
    if (!memberLocationHash) return;

    const memberGeohashCoordinates = decodeGeoHash(memberLocationHash);
    console.log("memberGeohashCoordinates: ", memberGeohashCoordinates);
    const radiusInM = 10 * 1609.34; // 10 miles in meters, adjust as needed

    const bounds = geohashQueryBounds(memberGeohashCoordinates, radiusInM);

    const defaultAddressKey = memberPrivateData?.defaultAddress;
    const matchedAddress = data?.account?.addresses?.[defaultAddressKey];

    if (!matchedAddress) return;

    const memberCoordinates = {
      lat: matchedAddress.lat,
      lng: matchedAddress.lng,
    };
    console.log("memberCoordinates: ", memberCoordinates);

    const eventsRef = collection(db, "events"); // Adjust according to your events collection name
    const eventUnsubscribes = [];

    bounds.forEach(([start, end]) => {
      const eventsQuery = query(
        eventsRef,
        where("status", "==", "active"), // Filter events where status is "active"
        orderBy("location.geohash"),
        startAt(start),
        endAt(end)
      );

      const unsubscribe = onSnapshot(eventsQuery, (querySnapshot) => {
        const newEvents = {};
        querySnapshot.forEach((doc) => {
          const eventData = doc.data();

          console.log("memberCoordinates: ", memberCoordinates);
          console.log("eventData.location.bounds: ", eventData.location.bounds);

          // Assuming eventData.location.bounds is an array of { lat, lng } points defining the polygon
          // Assuming memberCoordinates are a { lat, lng } pair
          if (isPointInPolygon(memberCoordinates, eventData.location.bounds)) {
            console.log("is within bounds");
            newEvents[doc.id] = eventData;
          }
        });

        // Update the state with new events within maxDistance
        setData((prevData) => ({
          ...prevData,
          events: { ...prevData.events, ...newEvents },
        }));
      });

      eventUnsubscribes.push(unsubscribe);
    });

    // Cleanup function
    return () => {
      eventUnsubscribes.forEach((unsub) => unsub());
    };
  }, [
    memberPrivateData?.location?.geohash,
    memberPrivateData?.defaultAddress,
    data?.account?.addresses,
  ]); // Dependency array

  useEffect(() => {
    if (!memberData?.timeZoneId) return;

    const timeZoneId = memberData?.timeZoneId;

    const now = moment().tz(timeZoneId);
    let next = null;
    let prev = null;

    // Convert visits to an array and sort by the 'start' field
    const sortedVisits = Object.entries(visitsData || {})
      .map(([id, visit]) => ({ ...visit, id }))
      .sort((a, b) => a.start.toDate() - b.start.toDate());

    // Iterate over the sorted visits to find the previous and next visits
    for (let visit of sortedVisits) {
      if (visit.status === "cancelled" || visit.status === "reschedule") {
        continue; // Skip cancelled visits
      }

      const startMoment = moment(visit.start.toDate()).tz(timeZoneId);

      if (startMoment.isAfter(now)) {
        next = { ...visit }; // Assign the next visit directly
        break; // Break the loop after finding the next visit
      } else if (startMoment.isBefore(now)) {
        // Check if at least one employee in .employees has responseStatus "accepted"
        const hasAcceptedEmployee = Object.values(visit.employees || {}).some(
          (employee) => employee.responseStatus === "accepted"
        );
        if (hasAcceptedEmployee) {
          prev = { ...visit }; // Update prev only if the condition is met
        }
      }
    }

    setNextVisit(next);
    setPrevVisit(prev);
  }, [visitsData]);

  if (loadingMemberData || loadingMemberPrivateData || loadingEmployees) {
    return (
      <LoadingUser
        loading={
          loadingMemberData || loadingMemberPrivateData || loadingEmployees
        }
      />
    );
  }

  return (
    <MemberContext.Provider
      value={{
        memberData,
        loadingMemberData,

        memberPrivateData,
        loadingMemberPrivateData,

        memberAccountData,
        loadingAccountData,

        memberAdminData,
        loadingMemberAdminData,

        data,
        setData,

        visitsData,
        loadingVisitsData,

        nextVisit,
        prevVisit,
      }}
    >
      {children}
    </MemberContext.Provider>
  );
};
